import { render, staticRenderFns } from "./announceListCopy.vue?vue&type=template&id=792c5033"
import script from "./announceListCopy.vue?vue&type=script&lang=js"
export * from "./announceListCopy.vue?vue&type=script&lang=js"
import style0 from "./announceListCopy.vue?vue&type=style&index=0&id=792c5033&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
  <a-modal v-model:refundvisible="refundvisible" title="申请退还保证金" @ok="handleOk" @cancel="handleCancel"  okText="确定"
cancelText="关闭">
    <a-form-model
      :model="refundform"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      ref="refundform"
      :rules="validatorRules"
    >
      <a-form-model-item label="退还材料" prop="refundReasonAttachment" :key="fileKey">
        <a-upload
          ref="uploadclear"
          v-model:file-list="fileList"
          name="multipartFile"
          :multiple="false"
          :action="uploadImgUrl"
          :remove="removechange"
          :before-upload="beforeUpload"
          @change="uploadchange"
        >
          <a-button :disabled="fileList.length != 0"> 选取文件 </a-button>
        </a-upload>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { postTeAction } from '@/api/index'
import backEndAddressUrl from '../../utils/backEndAddressUrl'
export default {
  data() {
    return {
      refundvisible: false,
      // 退款表单
      refundform: {
        announcementId: '', //公告id
        registrationStatus: '5',
        refundReasonAttachment: '', //退款材料证明
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      fileList: [],
      uploadImgUrl: backEndAddressUrl.backEndUrl + '/file/save',
      loginTken: '',
      fileKey: '',
      url: {
        refundedit: '/project/bidProject/refund',
      },
      validatorRules: {
        refundReasonAttachment: [{ required: true, message: '请上传退款材料！', trigger: 'change' }],
      },
    }
  },
  methods: {
    // 打开退款申请
    openARefund(Anid, usertoken) {
      this.fileKey = Math.random()
      //   this.defaultFileList = []
      this.resetform()
      //   this.$refs.uploadclear.clearFiles()
      //   this.uploadImgUrl = window._CONFIG['domianURL'] + '/file/save'
      console.log('文件上传url:', this.uploadImgUrl)
      console.log('公告id:', Anid)
      console.log('表单情况：', this.fileList, this.refundform)
      this.refundform.announcementId = Anid
      this.loginTken = usertoken
      this.refundvisible = true
    },
    // 提交退款申请
    handleOk() {
      console.log('申请退款')
      this.$refs.refundform.validate((valid) => {
        if (valid) {
          const customHeaders = {
            Authorization: this.loginTken,
          }
          postTeAction(this.url.refundedit, this.refundform, customHeaders).then((res) => {
            // console.log(res)
            // console.log(this.refundform)
            if (res.code == 200) {
              this.refundvisible = false
              this.$message.warning('申请成功')
              this.$emit('child-event', '调用退款')
            } else {
              this.$message.warning(res.message)
            }
          })
        } else {
        }
      })
    },
    // 关闭退款申请
    handleCancel() {
      console.log('取消退款')
      this.refundvisible = false
      //   this.$refs.uploadclear.clearFiles()
    },
    // 重置表单
    resetform() {
      this.refundform = {
        announcementId: '',
        refundReasonAttachment: '',
      }
      this.fileList = []
      this.loginTken = ''
    },
    // 文件上传、成功、失败
    uploadchange(info) {
      //   console.log('文件上传', info)
      if (info.fileList.length != 0) {
        if (info.fileList[0].status === 'done') {
          const response = info.fileList[0].response
          //   console.log(response)
          if (response && response.success) {
            // console.log('上传成功:', response.message, info) // 文件访问链接
            this.fileList = info.fileList
            this.refundform.refundReasonAttachment = response.message
          } else {
            console.log('上传失败')
            this.fileList = []
          }
        } else if (info.fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileList = []
        }
      }
    },
    // 文件删除
    removechange(info) {
      // console.log('点击删除',info)
      ;(this.refundform.refundReasonAttachment = ''), (this.fileList = [])
    },
    // 文件校验
    beforeUpload(file,info,fileList) {
      console.log(file,fileList,this.fileList,info)
      const maxSizeInBytes = 500 * 1024 * 1024 // 5 MB (根据您的需求设置最大文件大小)

      if (file.size > maxSizeInBytes) {
        this.$message.error('文件大小超过限制（最大500MB）')
        info.shift()
        return false // 取消上传
      }

      return true // 允许上传
    },
  },
}
</script>

<style></style>
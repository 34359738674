<template>
  <div id="app">
    <a-config-provider :locale="locale">
        <!-- 全局配置就用a-config-provider把所有内容包裹就行，我这里是通过路由跳转把所有页面整合在了一起 -->
        <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN';

export default {
  components: {
    // 'a-config-provider': ConfigProvider
  },
  data() {
    return {
      locale: zhCN
    };
  }
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
}
</style>

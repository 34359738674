<template>
  <div style="padding: 0 100px">
    <div>
      <a-button type="danger" shape="circle" class="fhbtn" size="small" @click="returnOperation()"><a-icon
          type="arrow-left" /></a-button>
    </div>
    <div class="details_middle">
      <p style="text-align: center; font-size: 28px; font-weight: 400; color: #1f2f3d">{{ recorddetails.title }}</p>
      <p style="text-align: center; font-size: 14px; color: #5e6d82; line-height: 1.5em">
        <!-- <span v-if="recorddetails.type == 1">报名时间：{{ recorddetails.registrationValidityTime }}</span>
        <span v-if="recorddetails.type == 1" style="margin: 0 15px">开标时间：{{ recorddetails.bidOpeningTime }}</span> -->
        <span>发布时间：{{ recorddetails.releaseTime }}</span>
      </p>
      <div style="padding: 0 15px">
        <a-divider />
      </div>

      <!-- <span>登录情况：{{ txtlogin }}</span>
      <span>项目状态：{{ recorddetails.projectStatus }}</span>
      <span>报名状态：{{ recorddetails.registrationStatus }}</span> -->

      <!-- 按钮-登录之前 -->
      <div v-if="txtlogin == '没登录信息' &&
        // recorddetails.projectStatus != 4 &&
        recorddetails.projectStatus != 5 &&
        recorddetails.projectStatus != 9 &&
        recorddetails.type == 1
        " style="text-align: right">
        <a-button type="primary" class="originalbtn" @click="notregistered()">
          <span>报名</span>
        </a-button>
      </div>
      <!-- 按钮-登录之后 -->
      <div v-if="txtlogin == '有登录信息' &&
        recorddetails.projectStatus != 4 &&
        recorddetails.projectStatus != 5 &&
        recorddetails.projectStatus != 7 &&
        recorddetails.projectStatus != 9 &&
        recorddetails.type == 1
        " style="text-align: right">
        <a-button v-if="recorddetails.type == 1 &&
          (recorddetails.registrationStatus == null || recorddetails.registrationStatus == 1) &&
          recorddetails.projectStatus != 4 &&
          recorddetails.projectStatus != 5 &&
          recorddetails.projectStatus != 7 &&
          recorddetails.projectStatus != 9 &&
          (userInformation.roleType == 1 ||
            userInformation.roleType == null ||
            userInformation.roleType == undefined ||
            userInformation.roleType == '')
          " type="primary" class="originalbtn"
          @click="registrationandpayment(recorddetails.registrationStatus, '调用报名')">
          <span>报名</span>
        </a-button>
        <a-button v-if="recorddetails.type == 1 &&
          recorddetails.registrationStatus == 2 &&
          recorddetails.projectStatus != 4 &&
          recorddetails.projectStatus != 5 &&
          recorddetails.projectStatus != 7 &&
          recorddetails.projectStatus != 9 &&
          (userInformation.roleType == 1 ||
            userInformation.roleType == null ||
            userInformation.roleType == undefined ||
            userInformation.roleType == '')
          " type="primary" class="xqbmbtn" style="padding: 0 15px; margin-bottom: 15px"
          @click="registrationandpayment(recorddetails.registrationStatus, '调用缴费')">
          <span>缴费</span>
        </a-button>
      </div>

      <div class="tstimg" style="max-width: 900px; margin: 0 auto; font-size: 14px; color: #5e6d82; line-height: 1.5em">
        <div class="tsbj" v-html="recorddetails.illustrate"></div>
      </div>

      <!-- <span v-if="judgmentsupplement == 'yes'">
        <div
          style="
            width: 150px;
            background-color: #302b63;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-top: 45px;
            border-radius: 100px;
          "
        >
          <p style="color: #fff">补充内容</p>
        </div>
        <div
          class="tstimg"
          style="max-width: 900px; margin: 0 auto; font-size: 14px; color: #5e6d82; line-height: 1.5em"
        >
          <div v-html="supplementdata.illustrate"></div>
        </div>
      </span> -->
    </div>
    <div style="height: 100px; width: 100%"></div>
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction, httpAction, getTeAction } from '@/api/index'
export default {
  data() {
    return {
      recorddetails: {},
      url: {
        queryById: '/project/bidAnnouncement/queryById', //通过id查询详情内容
        queryBcgg: '/project/bidAnnouncement/list', //暂时通过项目id来查询补充公告
        querySupplement: '/project/bidAnnouncement/supplement',
      },
      supplementdata: {},
      judgmentsupplement: 'no',
      txtlogin: '',
      userInformation: {},
    }
  },
  created() {
    // this.userInformation = {}
    // this.txtlogin = ''
    // if (storedData) {
    //   console.log('有登录信息')
    //   this.txtlogin = '有登录信息'
    //   this.userInformation = JSON.parse(storedData)
    //   this.formState = JSON.parse(JSON.stringify(this.userInformation))
    // } else {
    //   console.log('没登录信息')
    //   this.txtlogin = '没登录信息'
    // }
    // console.log(this.txtlogin)
  },
  methods: {
    // 获取数据
    obtainDetails(record, action) {
      // console.log('测试功能', action)
      const storedData = sessionStorage.getItem('txtuserData')
      if (storedData) {
        this.txtlogin = '有登录信息'
        this.userInformation = JSON.parse(storedData)
        this.formState = JSON.parse(JSON.stringify(this.userInformation))
      } else {
        // this.getuser()
        this.txtlogin = '没登录信息'
      }

      this.recorddetails = {}
      console.log('获取相关数据', record)
      if (action == '详情') {
        this.recorddetails = record
        getAction('/project/bidAnnouncement/queryById?id='+record.id).then((tsres) => {
          console.log(tsres)
          this.recorddetails.illustrate = tsres.result.illustrate
        })

      } else if (action == '有补充公告') {
        let supplementData = {
          announcementState: '1',
          tenderProjectId: record.tenderProjectId,
          type: '3',
        }

        postAction(this.url.querySupplement, supplementData).then((res) => {
          console.log('3', res)
          this.recorddetails = res.result[0]
        })
      }

      this.supplementdata = {}
      this.judgmentsupplement = 'no'
      // if (record.type != 3) {
      // this.querysupplement()
      // }
    },
    // 返回列表页
    returnOperation() {
      console.log('返回列表页')
      this.$emit('child-event', '查看列表页')
    },
    registrationandpayment(txt, differentiate) {
      console.log('判断需要报名还是缴费：', txt)
      this.$emit('child-event', differentiate, this.recorddetails)
    },
    // 查询补充公告
    // querysupplement() {
    //   getAction(
    //     this.url.queryBcgg +
    //       '?tenderProjectId=' +
    //       this.recorddetails.tenderProjectId +
    //       '&announcementState=' +
    //       1 +
    //       '&type=' +
    //       3
    //   ).then((res) => {
    //     console.log(res)
    //     if (res.code == 200) {
    //       if (res.result.records.length != 0) {
    //         this.judgmentsupplement = 'yes'
    //         this.supplementdata = res.result.records[0]
    //       }
    //     } else {
    //       console.log('查询补充公告失败')
    //     }
    //   })
    // },
    // 查询是否登录
    getuser() {
      this.userInformation = {}
      this.txtlogin = ''
      const storedData = sessionStorage.getItem('txtuserData')
      if (storedData) {
        console.log('有登录信息')
        this.txtlogin = '有登录信息'
        this.userInformation = JSON.parse(storedData)
        this.formState = JSON.parse(JSON.stringify(this.userInformation))
        // 获取当前项目的报名状态
        const customHeaders = {
          // 在这里设置请求头，例如Authorization等
          Authorization: this.userInformation.token,
        }
        getTeAction(
          '/project/bidAnnouncement/listLogin' +
          '?type=' +
          this.recorddetails.type +
          '&announcementState=' +
          1 +
          '&id=' +
          this.recorddetails.id,
          {},
          customHeaders
        ).then((ress) => {
          console.log('2', ress)
          if (ress.code == 200) {
            this.recorddetails = ress.result.records[0]

            this.supplementdata = {}
            this.judgmentsupplement = 'no'
            // if (record.type != 3) {
            // this.querysupplement()
            // }
          } else {
          }
        })
      } else {
        console.log('没登录信息')
        this.txtlogin = '没登录信息'
      }
      // console.log('登录状态打印', this.txtlogin, this.recorddetails)
    },
    // 未登录时候点击报名
    notregistered() {
      // this.$emit('customEvent', '调用登录');
      this.$emit('child-event', '调用登录')
    },
  },
}
</script>

<style scoped>
.details_middle {
  /* background-color: red; */
  width: 100%;
  min-height: 500px;
  margin-top: 15px;
}

.xqbmbtn {
  background-color: #ff4400 !important;
  border: none;
  width: 150px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}

.xqbmbtn:hover {
  opacity: 0.8;
  background-color: #ff4400 !important;
}

.fhbtn {
  padding: 0 15px;
  margin-left: -45px;
  margin-top: 15px;
  height: 50px;
  width: 50px;
  line-height: 50px;
  border-radius: 50%;
}
</style>

<style>
.tstimg img {
  max-width: 100% !important;
  /* 图片最大宽度为父容器的宽度 */
}

.tsbj div {
  background-color: transparent !important;
}

.originalbtn {
  padding: 0 15px;
  margin-bottom: 15px;
  width: 150px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
}
</style>
<template>
    <!-- 支付保证金 -->
    <a-modal :title="title" :footer="null" :width="800" :visible="visible" :confirmLoading="confirmLoading" @ok="handleOk"
        @cancel="handleCancel" cancelText="关闭">
        <a-spin :spinning="confirmLoading" style="margin-left: 20px;">
            <p>项目名称：{{ model.title }}</p>
            <p style="display: flex;justify-content: space-between;">
                <span>投标公司：{{ modelUserInfo.company }}</span><span>联系人：{{ modelUserInfo.contacts }}</span><span>电话：{{
                    modelUserInfo.phone }}</span>
            </p>
            <div class="file_download">
                <img src="../../assets/image/downloadFile.png" alt="/" >
                <p>{{ fileSize }}MB</p>
                <a-button class="file_download_need" type="primary" @click="fileDownloadBtnFun()">
                    下载
                </a-button>
            </div>
        </a-spin>
    </a-modal>
</template>
  
<script>
import { httpAction, downFile, postTeFileAction } from '@/api/index'

export default {
    name: "AnnounceFileDownload",
    data() {
        return {
            title: "招标文件下载",
            visible: false,
            model: {},
            modelUserInfo: {},
            QrListArr: ["https://w.wallhaven.cc/full/5g/wallhaven-5gm823.jpg",],
            needInvoice: "check",
            NoNeedInvoice: "",
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            confirmLoading: false,
            form: this.$form.createForm(this),
            validatorRules: {},
            fileSize: "",
            fileBolb: "",
            url: {
                add: "/test/jeecgDemo/add",
                edit: "/test/jeecgDemo/edit",
            },
        }
    },
    created() {
    },
    methods: {
        edit(record, userInfo) {
            console.log("报名传参", record)
            this.model = Object.assign({}, record);
            this.modelUserInfo = Object.assign({}, userInfo);
            if ( this.model.bidFile == null) {
                return this.$message.warning('当前暂无招标文件')
            }
            this.confirmLoading = true
            let doewurl = "/file/upload?fileName=" + this.model.bidFile
            const customHeaders = {
                // 在这里设置请求头，例如Authorization等
                Authorization: this.modelUserInfo.token,
            }
            postTeFileAction(doewurl, "", customHeaders).then((data) => {
                console.log(data)
                this.fileSize = (data.size / (1024 * 1024)).toFixed(2); // 转换为兆字节数，保留两位小数
                this.fileBolb = data
                this.confirmLoading = false
            }).finally(() => {
                 this.confirmLoading = false;
          })
            this.visible = true;
        },
        close() {
            // this.$refs.form.resetFields();
            this.$emit('close');
            this.visible = false;
            
        },
        fileDownloadBtnFun() {
            console.log("文件下载")
            const dotIndex = this.model.bidFile.lastIndexOf(".");
            var fileNameNO
            if (dotIndex !== -1) {
                fileNameNO = this.model.bidFile.slice(dotIndex + 1);
            } else {
            }
            if (!this.fileBolb || this.fileBolb.size === 0) {
                Vue.prototype['$message'].warning('文件下载失败')
                return
            }
            if (typeof window.navigator.msSaveBlob !== 'undefined') {
                window.navigator.msSaveBlob(new Blob([this.fileBolb]), "招标文件." + fileNameNO)
            } else {
                let url = window.URL.createObjectURL(new Blob([this.fileBolb]))
                let link = document.createElement('a')
                link.style.display = 'none'
                link.href = url
                link.setAttribute('download', "招标文件." + fileNameNO)
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link) //下载完成移除元素
                window.URL.revokeObjectURL(url) //释放掉blob对象
            }
        },
        handleOk() {
            const that = this;
            // 触发表单验证
            this.$refs.form.validate(valid => {
                if (valid) {
                    //   that.confirmLoading = true;
                    //   let httpurl = '';
                    //   let method = '';
                    //   if(!this.model.id){
                    //     httpurl+=this.url.add;
                    //     method = 'post';
                    //   }else{
                    //     httpurl+=this.url.edit;
                    //      method = 'put';
                    //   }
                    console.log("校验")
                    //   httpAction(httpurl,this.model,method).then((res)=>{
                    //     if(res.success){
                    //       that.$message.success(res.message);
                    that.$emit('ok');
                    this.visible = false;
                    //     }else{
                    //       that.$message.warning(res.message);
                    //     }
                    //   }).finally(() => {
                    //     that.confirmLoading = false;
                    //     that.close();
                    //   })
                }
            })
        },
        handleCancel() {
            this.close()
        }
    }
}
</script>
  
<style scoped>
.file_download {
    width: 100%;
    /* margin: 0 auto; */
    /* border: 1px solid #e0e0e0; */
    border-radius: 10px;
    text-align: center;
}

.file_download img {
    width: 150px;
    max-height: 200px;
    
    margin: 50px 20px 20px 20px;
}

.file_download_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>
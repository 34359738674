import { render, staticRenderFns } from "./announceList.vue?vue&type=template&id=1a7ad613&scoped=true"
import script from "./announceList.vue?vue&type=script&lang=js"
export * from "./announceList.vue?vue&type=script&lang=js"
import style0 from "./announceList.vue?vue&type=style&index=0&id=1a7ad613&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7ad613",
  null
  
)

export default component.exports
<template>
  <div>
    <!-- 报名 -->
    <a-modal :title="title" :width="800" :visible="visible" :confirmLoading="confirmLoading" @ok="handleOk" okText="确定"
      @cancel="handleCancel" cancelText="关闭">
      <a-spin :spinning="confirmLoading" style="margin-left: 20px">
        <a-form-model ref="form" labelAlign="left" :label-col="labelCol" :wrapper-col="wrapperCol" :model="modelUserInfo"
          :rules="validatorRules">
          <a-form-model-item label="项目名称">
            <span>{{ model.tenderProjectId_dictText }}</span>
          </a-form-model-item>
          <p>【确认信息】</p>
          <a-form-model-item label="邮箱" prop="mail" hasFeedback>
            <a-input v-model="modelUserInfo.mail" placeholder="请输入邮箱" />
          </a-form-model-item>
          <a-form-model-item label="公司" prop="company" hasFeedback>
            <a-input v-model="modelUserInfo.company" placeholder="请输入公司" />
          </a-form-model-item>
          <a-form-model-item label="营业执照" prop="businessLicense">
            <div class="clearfix">
              <a-upload name="multipartFile" :multiple="false" :action="uploadImgUrl" list-type="picture-card"
                :file-list="fileImgList" :beforeUpload="beforeImgUpload" @preview="handleImgPreview('执照')"
                @change="tehandleImgChange">
                <div v-if="fileImgList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="PreviewhandleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
              <label style="color: red">请传上尺寸在800x800，大小在1M以内的图片</label>
            </div>
          </a-form-model-item>
          <a-form-model-item label="附件" prop="assertImgs" hasFeedback>
            <!-- <j-image-upload isMultiple v-model="model.assertImgs"></j-image-upload> -->
            <div class="clearfix">
              <a-upload name="multipartFile" :multiple="true" :action="uploadFileUrl" :file-list="fileList"
                @preview="handlePreview('执照')" @change="tehandleChange">
                <!-- <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传</div>
                </div> -->
                <a-button v-if="fileList.length < 1"> <a-icon type="upload" /> 点击上传 </a-button>
              </a-upload>
              <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="PreviewhandleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
              <!-- <label style="color: red">请传上尺寸在800x800，大小在1M以内的图片</label> -->
            </div>
          </a-form-model-item>
          <a-form-model-item label="联系人" prop="contacts" hasFeedback>
            <a-input v-model="modelUserInfo.contacts" placeholder="请输入联系人" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="phone" hasFeedback>
            <a-input v-model="modelUserInfo.phone" placeholder="请输入手机号" />
          </a-form-model-item>
          <a-form-model-item label="其他电话">
            <a-input v-model="modelUserInfo.otherPhone" placeholder="请输入其他电话" />
          </a-form-model-item>
        </a-form-model>
      </a-spin>
    </a-modal>

    <Announce-Deposit-Payment ref="DepositPayment" @child-event="refreshList"></Announce-Deposit-Payment>
    <Announce-File-Download ref="announceFileDownload"></Announce-File-Download>
  </div>
</template>
  
<script>
import { httpAction, getAction, postTeAction, postTeFileAction } from '@/api/index'
import AnnounceDepositPayment from './AnnounceDepositPayment.vue'
import AnnounceFileDownload from './AnnounceFileDownload.vue'
import backEndAddressUrl from '../../utils/backEndAddressUrl'
export default {
  name: 'AnnounceApply',
  components: {
    AnnounceDepositPayment,
    AnnounceFileDownload,
  },
  data() {
    return {
      title: '报名',
      visible: false,
      model: {},
      modelUserInfo: {},
      modelUserInfoCopy: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 3 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      validatorRules: {
        company: [
          { required: true, message: '请输入公司名称!' },
          { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' },
        ],
        mail: [{ required: true, type: 'email', message: '邮箱格式不正确！', trigger: 'blur' }],
        // assertImgs: [
        //   { required: true, message: '请上传营业执照！' }
        // ],
        phone: [{ required: true, validator: this.validateMobile, trigger: 'blur' }],
        contacts: [
          { required: true, message: '请输入联系人!' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' },
        ],
      },
      url: {
        // 报名
        add: '/project/bidProject/add',
        // 修改用户信息
        edit: '/user/zongshiUser/edit',
        getip: '/file/getIP', //获取本机ip-get
      },
      fileList: [],
      fileImgList:[],
      previewVisible: false,
      previewImage: '',
      ipdz: '',
      uploadImgUrl: '',
      uploadFileUrl:'',
      uploadBlocked: false
    }
  },
  created() { },
  methods: {
    validateMobile(rule, value, callback) {
      if (new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)) {
        callback()
      } else {
        callback('您的手机号码格式不正确!')
      }
    },
    add() {
      this.edit({})
    },
    edit(record, userInfo) {
      console.log('报名传参', record, userInfo)
      this.fileList = []
      this.fileImgList = []
      this.fileList.push({
        uid: '-1',
        name: userInfo.files,
        status: 'done',
        url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + userInfo.files,
      })
      this.fileImgList.push({
        uid: '-1',
        name: userInfo.businessLicense,
        status: 'done',
        url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + userInfo.businessLicense,
      })
      // getAction(this.url.getip).then((res) => {
      //   // console.log(res)
      //   if (res.code == 200) {
      //     this.ipdz = res.result
      //     // this.uploadImgUrl = 'http://' + res.result + '/jeecg-boot/file/save'
      //   } else {
      //     this.ipdz = ''
      //     this.uploadImgUrl = ''
      //     this.$message.warning('获取IP地址失败')
      //   }
      // })
      this.uploadImgUrl = ''
      this.uploadImgUrl = backEndAddressUrl.backEndUrl + '/file/saveImg'
      this.uploadFileUrl = ''
      this.uploadFileUrl = backEndAddressUrl.backEndUrl + '/file/saveFile'
      this.model = Object.assign({}, record)
      this.modelUserInfo = Object.assign({}, userInfo)
      this.modelUserInfoCopy = Object.assign({}, userInfo)
      this.visible = true
    },
    close() {
      this.$refs.form.resetFields()
      this.$emit('close')
      this.visible = false
    },
    isObjectEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
},
    deepCompare(obj1, obj2) {
      console.log(  obj1, obj2)
      const keys1 = Object.keys(obj1)
      const keys2 = Object.keys(obj2)

      if (keys1.length !== keys2.length) {
        return false
      }

      for (const key of keys1) {
        console.log(key)
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          
          if (!this.deepCompare(obj1[key], obj2[key])) {
            return false
          }
        } else if (obj1[key] !== obj2[key]) {
          return false
        }
      }

      return true
    },
    handleOk() {
      const that = this
      // 触发表单验证
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 添加报名
          var registrationInfo = {
            businessLicense: this.modelUserInfo.businessLicense,
            contacts: this.modelUserInfo.contacts,
            mail: this.modelUserInfo.mail,
            otherContacts: this.modelUserInfo.otherPhone,
            phone: this.modelUserInfo.phone,
            bidder: this.modelUserInfo.username,
            // 报名状态
            registrationStatus: '2',
            // 是否申请退款
            refund: '1',
            // 项目id
            tenderProjectId: this.model.tenderProjectId,
          }

          if (this.model.bond == null || this.model.bond == 0) {
            registrationInfo.registrationStatus = '4'
          } else {
          }
          const customHeaders = {
            // 在这里设置请求头，例如Authorization等
            Authorization: this.modelUserInfo.token,
          }
          that.confirmLoading = true
          // console.log(this.deepCompare(this.modelUserInfoCopy, this.modelUserInfo))
          // console.log('校验', this.model, this.modelUserInfo)
          // 修改用户信息
          if (this.isObjectEqual(this.modelUserInfoCopy, this.modelUserInfo) == false) {
            postTeAction(this.url.edit, this.modelUserInfo, customHeaders).then((res) => {
              console.log(res)
              if (res.code == 200) {
                sessionStorage.removeItem('txtuserData')
                sessionStorage.setItem('txtuserData', JSON.stringify(this.modelUserInfo))
                this.$message.success('修改信息成功')
                console.log('添加报名', registrationInfo)
                postTeAction(this.url.add, registrationInfo, customHeaders)
                  .then((res) => {
                    if (res.code == 200) {
                      that.$message.success('报名成功！')
                      that.$emit('ok')
                      that.visible = false

                      // 判断是否需要缴纳保证金
                      if (this.model.bond == null || this.model.bond == 0) {
                        that.$refs.announceFileDownload.edit(this.model, this.modelUserInfo)
                      } else {
                        that.$refs.DepositPayment.edit(this.model, this.modelUserInfo)
                      }
                      that.confirmLoading = false
                    } else {
                      that.visible = false
                      // that.$message.warning(res.message);
                      this.countDown(res.message)
                    }
                  })
                  .finally(() => {
                    that.confirmLoading = false
                  })
              } else {
                this.$message.warning('修改信息失败，' + res.message)
                that.confirmLoading = false
              }
            })
          } else {
            console.log('添加报名', registrationInfo)
            postTeAction(this.url.add, registrationInfo, customHeaders)
              .then((res) => {
                if (res.code == 200) {
                  that.$message.success('报名成功！')
                  that.$emit('ok')
                  that.visible = false

                  // 判断是否需要缴纳保证金
                  if (this.model.bond == null || this.model.bond == 0) {
                    that.$refs.announceFileDownload.edit(this.model, this.modelUserInfo)
                  } else {
                    that.$refs.DepositPayment.edit(this.model, this.modelUserInfo)
                  }
                  that.confirmLoading = false
                } else {
                  that.visible = false
                  // that.$message.warning(res.message);
                  this.countDown(res.message)
                }
              })
              .finally(() => {
                that.confirmLoading = false
              })
          }



          //   httpAction(httpurl,this.model,method).then((res)=>{
          //     if(res.success){
          //       that.$message.success(res.message);

          //     }else{
          //       that.$message.warning(res.message);
          //     }
          //   }).finally(() => {
          //     that.confirmLoading = false;
          //     that.close();
          //   })
        }
      })
    },
    handleCancel() {
      this.close()
    },
    // 上传图片相关
    handlePreview(file) {
      console.log('点击查看图片', file)
      // this.previewImage.push({url: this.zcformState.businessLicense})
      if (file == '执照') {
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.modelUserInfo.files
        let doewurl = "/file/upload?fileName=" + this.modelUserInfo.files
        const customHeaders = {
          // 在这里设置请求头，例如Authorization等
          Authorization: this.modelUserInfo.token,
        }
        postTeFileAction(doewurl, "", customHeaders).then((data) => {
          console.log(data)
          this.fileDownloadBtnFun(data)
          // this.fileSize = (data.size / (1024 * 1024)).toFixed(2); // 转换为兆字节数，保留两位小数
          // this.fileBolb = data
          // this.confirmLoading = false
        }).finally(() => {
          //  this.confirmLoading = false;
        })
      } else if (file == '证书') {
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.modelUserInfo.files
        this.previewVisible = true
      }

    },
    fileDownloadBtnFun(data) {
      console.log("文件下载")
      const dotIndex = this.modelUserInfo.files.lastIndexOf(".");
      var fileNameNO
      if (dotIndex !== -1) {
        fileNameNO = this.modelUserInfo.files.slice(dotIndex + 1);
      } else {
      }
      if (!data || data.size === 0) {
        Vue.prototype['$message'].warning('文件下载失败')
        return
      }
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(new Blob([data]), "附件." + fileNameNO)
      } else {
        let url = window.URL.createObjectURL(new Blob([data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', "附件." + fileNameNO)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) //下载完成移除元素
        window.URL.revokeObjectURL(url) //释放掉blob对象
      }
    },
    tehandleChange({ fileList }) {
      //   console.log(fileList)
      this.fileList = fileList
      if (fileList.length != 0) {
        // console.log(fileList.length)
        console.log(fileList[0].status)
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            this.$message.success('上传成功')
            // getAction(this.url.getip).then((res) => {
            //   console.log(res)
            //   if (res.code == 200) {
            //     // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
            //     this.model.assertImgs = 'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
            //   } else {
            //     this.$message.warning('获取IP地址失败')
            //   }
            // })
            // this.model.assertImgs = 'http://' + this.ipdz + '/jeecg-boot/file/imgUrl/' + response.message
            this.model.assertImgs = response.message
            this.modelUserInfo.files = response.message
              console.log(this.modelUserInfo)
          } else {
            console.log('上传失败:', response.message)
            this.$message.error(response.message)
            this.fileList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.modelUserInfo.files = ''
      }
    },
    // 关闭预览
    PreviewhandleCancel() {
      this.previewVisible = false
    },
    refreshList() {
      this.$emit('child-event', '报名里的缴费')
    },
    countDown(text) {
      // let secondsToGo = 5
      const modal = this.$warning({
        title: '',
        content: text,
      })
      // const interval = setInterval(() => {
      //   secondsToGo -= 1
      //   modal.update({
      //     content: `该弹窗将在 ${secondsToGo} 秒后关闭`,
      //   })
      // }, 1000)
      // setTimeout(() => {
      //   clearInterval(interval)
      //   modal.destroy()
      // }, secondsToGo * 1000)
    },
    beforeImgUpload(file) {
      this.uploadBlocked = false
      const isLt1M = file.size / 1024 / 1024 < 1 // 限制文件大小为 1MB
      let image = new Image()
      if (!isLt1M) {
        this.$message.error('图片大小不能超过 1MB！')
        this.fileImgList = []
        this.csqk()
        this.uploadBlocked = true
        return false
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        image.src = e.target.result
        image.onload = () => {
          if (image.width <= 800 && image.height <= 800) {
            this.$message.success('图片尺寸和大小符合要求！')
            this.uploadBlocked = true
          } else {
            this.$message.error('图片尺寸必须在 800x800 以内！')
            this.fileImgList = []
            this.uploadBlocked = true
          }
        }
      }
      reader.readAsDataURL(file)
      this.uploadBlocked = false
      return true // 允许上传
    },
    handleImgPreview(file){
       // console.log('点击查看图片', file, this.formState.businessLicense)
       this.previewImage = ''
      if (file == '执照') {
        // this.previewImage = this.formState.businessLicense
        this.previewImage = backEndAddressUrl.backEndUrl+ '/file/imgUrl/' + this.modelUserInfo.businessLicense
      } else if (file == '证书') {
        // this.previewImage = this.formState.relevantCertificates
        this.previewImage =  backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.modelUserInfo.businessLicense
      }
      this.previewVisible = true
    },
    // 执照
    tehandleImgChange({ fileList }) {
      // console.log(fileList, fileList.length)
      // console.log('1111',this.uploadBlocked,fileList)
      if (this.uploadBlocked == false) {
        this.fileImgList = fileList
      }
      if (fileList.length != 0) {
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            // getAction(this.url.getip).then((res) => {
            //   console.log(res)
            //   if (res.code == 200) {
            // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
            // this.zcformState.businessLicense =
            //   'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
            // this.zcformState.businessLicense = window._CONFIG['domianURL'] + '/file/imgUrl/' + response.message
            this.modelUserInfo.businessLicense = response.message
            console.log(this.modelUserInfo)
            //   } else {
            //     this.$message.warning('获取IP地址失败')
            //   }
            // })
          } else {
            console.log('上传失败:', response.message)
            this.fileImgList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileImgList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.fileImgList = []
        this.modelUserInfo.businessLicense = ''
      }
    },
  },
}
</script>
  
<style scoped></style>
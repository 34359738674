<template>
  <div>
    <!-- 顶部 -->
    <div class="announce_header">
      <!-- 左边 -->
      <div class="announce_header_left">
        <img style="width: 80px; float: left; margin-top: 20px" src="@/assets/logo/logo_600.png" />
        <div style="float: left; padding-left: 10px">
          <p class="announce_header_left_h1">
            福建中实招标<label style="font-size: 40px; color: #8ec5fc; margin-left: 10px">在线报名系统</label>
          </p>
          <p style="color: white; font-size: 20px; font-family: 楷体; line-height: 20px">
            ✦旨在提供安全、快捷、方便的报名服务，使得参与招标变得更加轻松、高效。
          </p>
        </div>
      </div>
      <!-- 右边 -->
      <div class="announce_header_right">
        <div class="announce_header_right_btnone">
          <!-- @click="tzht()" -->
          <!-- <router-link :to="{ name: 'user-login' }"> -->
          <!-- <div class="announce_header_right_btnonetst"> -->
          <!-- <a-button type="primary" size="small" style="padding: 0 15px; border-radius: 50px" @click="tzht()"
            >后台</a-button
          > -->
          <span v-if="this.userInformation.username != undefined &&
            this.userInformation.username != '' &&
            this.userInformation.username != null
            " style="color: white; margin-left: 15px">欢迎回来，{{ this.userInformation.username }}</span>
          <!-- <span style="color: white" v-else>欢迎回来，未知的用户</span> -->
          <span v-if="this.userInformation.username != undefined &&
            this.userInformation.username != '' &&
            this.userInformation.username != null
            ">
            <a-button  size="small"
              style=" padding: 0 15px; border-radius: 50px; margin-left: 15px"
              @click="goHomePage('首页')">首页</a-button>
            <a-button type="primary" size="small"
              style="padding: 0 15px; border-radius: 50px; margin-right: 15px; margin-left: 15px; color: white"
              @click="seethatperson()">个人中心</a-button>
            <a-button type="danger" size="small" style="padding: 0 15px; border-radius: 50px"
              @click="Logout('退出登录')">退出</a-button></span>
          <!-- </router-link> -->

          <span v-if="this.userInformation.username == 'undefined' ||
            this.userInformation.username == '' ||
            this.userInformation.username == null
            ">
            <a-button  size="small"
              style=" padding: 0 15px; border-radius: 50px; margin-left: 15px"
              @click="goHomePage('首页')">首页</a-button>
            <a-button type="danger" size="small"
              style="margin-right: 15px; padding: 0 15px; border-radius: 50px; margin-left: 15px"
              @click="zdaction('注册')">注册</a-button>
            <a-button type="primary" size="small" style="padding: 0 15px; border-radius: 50px"
              @click="zdaction('登录')">登录</a-button>
          </span>
        </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- 中间 -->
    <div style="margin-top: 150px">
      <span v-if="viewpage == '查看列表页'">
        <div class="midland">
          <!-- <div class="midland_one">
          <div class="midland_one_left">
            福建中实招标在线报名系统旨在提供安全、快捷、方便的报名服务，使得参与招标变得更加轻松、高效。
          </div>
          <div class="midland_one_right">
            <span v-if="this.userInformation.username != undefined && this.userInformation.username != '' && this.userInformation.username != null"
              >欢迎回来，{{ this.userInformation.username }}</span
            >
            <span v-else>欢迎回来，未知的用户</span>
            <a-button
              type="primary"
              size="small"
              style="padding: 0 15px; border-radius: 50px; margin-right: 30px; margin-left: 15px"
              @click="seethatperson()"
              >个人中心</a-button
            >
          </div>
        </div> -->
          <div style="height: 30px"> </div>
          <div class="midland_three">
            <a-card>
              <!-- 查询区域 -->
              <div class="table-page-search-wrapper">
                <a-form layout="inline" @keyup.enter.native="querySearch">
                  <a-row :gutter="12">
                    <a-col :md="6" :sm="24">
                      <a-input v-model="valueone" placeholder="请输入公告名称关键字" />
                    </a-col>
                    <a-col :md="3" :sm="24">
                      <a-select placeholder="请选择" ref="select" v-model="valuetwo" @focus="focus" @change="handleChange">
                        <a-select-option v-for="(item, index) in options" :key="index" :value="item.itemValue">{{
                          item.itemText
                        }}</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :md="3" :sm="24">
                      <a-select placeholder="请选择" ref="select" v-model="valuetherr" @focus="focus" @change="handleChange">
                        <!-- <a-select-option value="">请选择</a-select-option> -->
                        <a-select-option value="3">未开标</a-select-option>
                        <a-select-option value="4">已开标</a-select-option>
                        <a-select-option value="5">已结束</a-select-option>
                        <!-- <a-select-option value="7">已归档</a-select-option> -->
                        <a-select-option value="9">已流标</a-select-option>
                      </a-select>
                    </a-col>
                    <a-col :md="1" :sm="12">
                      <a-button type="primary" style="padding: 0 15px" @click="querySearch">搜索</a-button></a-col>
                    <a-col :md="1" :sm="12">
                      <a-button type="primary" style="padding: 0 15px; margin-left: 15px"
                        @click="resetSearch">重置</a-button></a-col>
                  </a-row>
                </a-form>
              </div>
              <a-tabs v-model:activeKey="activeKey" @change="handleTabChange" size="large"
                style="margin-top: 10px;text-align: center;">
                <a-tab-pane key="4" tab="预发公告">
                  <a-table ref="table" size="middle" :scroll="{ x: 1080 }" bordered rowKey="id" :columns="columnsCopyOne"
                    :dataSource="dataSource" :pagination="ipagination" :loading="loading" class="j-table-force-nowrap"
                    @change="handleTableChange">
                    <template slot="empty">
                      <a-empty description="暂无数据" />
                    </template>
                    <span slot="action" slot-scope="text, record">
                      <!-- <a style="color: #333" @click="seeDetails('详情', record)">详情</a> -->
                      <a-button @click="seeDetails('详情', record)">详情</a-button>
                      <!-- <a-divider
                      v-if="
                        record.type == 1 &&
                        (record.registrationStatus == 1 ||
                          record.registrationStatus == null ||
                          record.registrationStatus == 2)
                      "
                      type="vertical"
                    />
                    <a
                      v-if="record.type == 1 && (record.registrationStatus == 1 || record.registrationStatus == null) && (this.userInformation != null)"
                      @click="ApplyMoadlFun(record)"
                      >报名</a
                    >
                    <a v-if="record.type == 1 && record.registrationStatus == 2 && (this.userInformation != null)">缴费</a> -->
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-tab-pane key="1" tab="招标公告">
                  <a-table ref="table" size="middle" :scroll="{ x: 1080 }" bordered rowKey="id" :columns="columns"
                    :dataSource="dataSource" :pagination="ipagination" :loading="loading" class="j-table-force-nowrap"
                    @change="handleTableChange">
                    <span slot="projectStatusSlot" slot-scope="text, record">
                      <a-tag style="margin-right: 0px !important" v-if="record.projectStatus == 3" color="green">{{
                        text
                      }}</a-tag>
                      <a-tag style="margin-right: 0px !important" v-else-if="record.projectStatus == 4" color="red">{{
                        text
                      }}</a-tag>
                      <a-tag style="margin-right: 0px !important" v-else-if="record.projectStatus == 5" color="purple">{{
                        text }}</a-tag>
                      <span v-else>{{ text }}</span>
                    </span>
                    <span slot="supplementSlot" slot-scope="text, record">
                      <span v-if="text == 1">
                        <!-- <a @click="seeDetails('有补充公告', record)">有</a> -->
                        <a-button type="danger" shape="circle" @click="seeDetails('有补充公告', record)"> 有 </a-button>
                      </span>
                      <span v-else>无</span>
                    </span>
                    <span slot="action" slot-scope="text, record">
                      <!-- <a style="color: #333" @click="seeDetails('详情', record)">详情</a> -->
                      <a-button @click="seeDetails('详情', record)">详情</a-button>
                      <!-- 报名 -->
                      <span v-if="record.type == 1 &&
                        (record.registrationStatus == 1 || record.registrationStatus == null) &&
                        record.projectStatus != 4 &&
                        record.projectStatus != 5 &&
                        record.projectStatus != 7 &&
                        record.projectStatus != 9 &&
                        // userInformation != null &&
                        (userInformation.roleType == 1 ||
                          userInformation.roleType == null ||
                          userInformation.roleType == undefined ||
                          userInformation.roleType == '')
                        ">
                        <a-divider type="vertical" />
                        <!-- <a
                          @click="ApplyMoadlFun(record)"
                          :class="{ '': isWithinTimeRange(record), inactive: !isWithinTimeRange(record) }"
                          >报名</a
                        > -->
                        <a-button type="primary" @click="ApplyMoadlFun(record)"
                          :class="{ '': isWithinTimeRange(record), inactive: !isWithinTimeRange(record) }">报名</a-button>
                      </span>
                      <!-- 缴费 -->
                      <span v-if="record.type == 1 &&
                        record.registrationStatus == 2 &&
                        record.projectStatus != 4 &&
                        record.projectStatus != 5 &&
                        record.projectStatus != 7 &&
                        record.projectStatus != 9 &&
                        userInformation != null &&
                        userInformation.roleType == 1 &&
                        userInformation.roleType != null &&
                        userInformation.roleType != undefined
                        ">
                        <a-divider type="vertical" />
                        <!-- <a style="color: red" @click="payFeesFun(record)">缴费</a> -->
                        <a-button type="danger" @click="payFeesFun(record)"> 缴费 </a-button>
                      </span>
                      <!-- 下载标书 -->
                      <span v-if="record.type == 1 &&
                        (record.registrationStatus == 3 || record.registrationStatus == 4) &&
                        record.projectStatus != 5 &&
                        record.projectStatus != 7 &&
                        record.projectStatus != 9 &&
                        userInformation != null &&
                        userInformation.roleType == 1 &&
                        userInformation.roleType != null &&
                        userInformation.roleType != undefined
                        ">
                        <a-divider type="vertical" />
                        <!-- <a style="color: green" @click="downloadTenderFun(record)">下载标书</a> -->
                        <a-button style="
                            color: white !important;
                            background-color: #87d068 !important;
                            border: #87d068 !important;
                          " @click="downloadTenderFun(record)">
                          下载标书
                        </a-button>
                      </span>
                      <!-- 退还保证金 -->
                      <span v-if="
                        // record.type == 1 &&
                        record.registrationStatus == 3 &&
                        (record.projectStatus == 5 || record.projectStatus == 7 || record.projectStatus == 9) &&
                        userInformation != null &&
                        userInformation.roleType == 1 &&
                        userInformation.roleType != null &&
                        userInformation.roleType != undefined
                        ">
                        <a-divider type="vertical" />
                        <!-- <a style="color: red" @click="reimbursement(record.id)">退还保证金</a> -->
                        <a-button type="danger" @click="reimbursement(record.id)"> 退还保证金 </a-button>
                      </span>
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-tab-pane key="2" tab="中标公告">
                  <a-table ref="table" size="middle" :scroll="{ x: 1080 }" bordered rowKey="id" :columns="columnsCopyTwo"
                    :dataSource="dataSource" :pagination="ipagination" :loading="loading" class="j-table-force-nowrap"
                    @change="handleTableChange">
                    <span slot="supplementSlot" slot-scope="text, record">
                      <span v-if="text == 1">
                        <!-- <a @click="jumpToSupplement('有补充公告', record)">有</a> -->
                        <a-button type="danger" shape="circle" @click="seeDetails('有补充公告', record)"> 有 </a-button>
                      </span>
                      <span v-else>无</span>
                    </span>
                    <span slot="action" slot-scope="text, record">
                      <!-- <a style="color: #333" @click="seeDetails('详情', record)">详情</a> -->
                      <a-button @click="seeDetails('详情', record)">详情</a-button>
                      <!-- 退还保证金 -->
                      <!-- <span
                      v-if="
                        record.type == 2 &&
                        record.registrationStatus == 3 &&
                        (record.projectStatus == 5 || record.projectStatus == 7) &&
                        userInformation != null &&
                        userInformation.roleType == 1 &&
                        userInformation.roleType != null &&
                        userInformation.roleType != undefined
                      "
                    >
                      <a-divider type="vertical" />
                      <a @click="reimbursement(record.id)">退还保证金</a>
                    </span> -->
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-tab-pane key="3" tab="补充公告">
                  <a-table ref="table" size="middle" :scroll="{ x: 1080 }" bordered rowKey="id" :columns="columnsCopyOne"
                    :dataSource="dataSource" :pagination="ipagination" :loading="loading" class="j-table-force-nowrap"
                    @change="handleTableChange">
                    <span slot="action" slot-scope="text, record">
                      <!-- <a style="color: #333" @click="seeDetails('详情', record)">详情</a> -->
                      <a-button @click="seeDetails('详情', record)">详情</a-button>
                      <!-- <a-divider
                      v-if="
                        record.type == 1 &&
                        (record.registrationStatus == 1 ||
                          record.registrationStatus == null ||
                          record.registrationStatus == 2)
                      "
                      type="vertical"
                    />
                    <a
                      v-if="record.type == 1 && (record.registrationStatus == 1 || record.registrationStatus == null) && (this.userInformation != null && this.userInformation.roleType == 1 &&this.userInformation.roleType != null &&this.userInformation.roleType != undefined)"
                      @click="ApplyMoadlFun(record)"
                      >报名</a
                    >
                    <a v-if="record.type == 1 && record.registrationStatus == 2 && (this.userInformation != null && this.userInformation.roleType == 1 &&this.userInformation.roleType != null &&this.userInformation.roleType != undefined)">缴费</a> -->
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-tab-pane key="5" tab="流标公告">
                  <a-table ref="table" size="middle" :scroll="{ x: 1080 }" bordered rowKey="id" :columns="columnsCopyTwo"
                    :dataSource="dataSource" :pagination="ipagination" :loading="loading" class="j-table-force-nowrap"
                    @change="handleTableChange">
                    <span slot="supplementSlot" slot-scope="text, record">
                      <span v-if="text == 1">
                        <!-- <a @click="jumpToSupplement('有补充公告', record)">有</a> -->
                        <a-button type="danger" shape="circle" @click="seeDetails('有补充公告', record)"> 有 </a-button>
                      </span>
                      <span v-else>无</span>
                    </span>
                    <span slot="action" slot-scope="text, record">
                      <!-- <a style="color: #333" @click="seeDetails('详情', record)">详情</a> -->
                      <a-button @click="seeDetails('详情', record)">详情</a-button>
                      <!-- <a-divider
                      v-if="
                        record.type == 1 &&
                        (record.registrationStatus == 1 ||
                          record.registrationStatus == null ||
                          record.registrationStatus == 2)
                      "
                      type="vertical"
                    />
                    <a
                      v-if="record.type == 1 && (record.registrationStatus == 1 || record.registrationStatus == null) && (this.userInformation != null)"
                      @click="ApplyMoadlFun(record)"
                      >报名</a
                    >
                    <a v-if="record.type == 1 && record.registrationStatus == 2 && (this.userInformation != null)">缴费</a> -->
                    </span>
                  </a-table>
                </a-tab-pane>
                <a-tab-pane key="6" tab="其他公告">
                  <a-table ref="table" size="middle" :scroll="{ x: 1080 }" bordered rowKey="id" :columns="columnsCopyOne"
                    :dataSource="dataSource" :pagination="ipagination" :loading="loading" class="j-table-force-nowrap"
                    @change="handleTableChange">
                    <span slot="action" slot-scope="text, record">
                      <!-- <a style="color: #333" @click="seeDetails('详情', record)">详情</a> -->
                      <a-button @click="seeDetails('详情', record)">详情</a-button>
                    </span>
                  </a-table>
                </a-tab-pane>
              </a-tabs>
            </a-card>
          </div>
        </div>
      </span>

      <span v-show="viewpage == '查看详情页'">
        <listdetail @child-event="handleChildEvent" ref="listdetailref"></listdetail>
      </span>
      <span v-if="viewpage == '查看个人中心'">
        <thatperson @child-event="handleChildEvent"></thatperson>
      </span>
    </div>

    <div style="height: 100px"></div>

    <!-- 报名 -->
    <Announce-Apply ref="modalForm" @ok="modalFormOk" @child-event="handleChildEvent"></Announce-Apply>
    <!-- 缴费 -->
    <Announce-Deposit-Payment ref="DepositPayment" @child-event="refreshList"></Announce-Deposit-Payment>
    <!-- 下载标书 -->
    <Announce-File-Download ref="announceFileDownload"></Announce-File-Download>
    <!-- 申请退还保证金 -->
    <Refund-Application-Form ref="drawback" @child-event="refreshList"></Refund-Application-Form>

    <!-- 选择注册、登录、找回密码 -->
    <a-modal v-model:visible="zdzvisible" :title="zdztitle" :width="700" :mask-closable="false" :footer="null"
      @cancel="handleCancel">
      <span v-if="zdztitle == '登录'">
        <a-form-model @keyup.enter.native="zdzdetermine('确定登录')" ref="formtone" :model="formState" :label-col="labelCol"
          :wrapper-col="wrapperCol" :rules="validatorRulestwo">
          <a-form-model-item label="账号" prop="username">
            <a-input v-model="formState.username" />
          </a-form-model-item>
          <a-form-model-item label="密码" prop="password">
            <a-input-password v-model="formState.password" />
          </a-form-model-item>
        </a-form-model>
        <span>
          <div style="text-align: center">
            <div style="margin-bottom: 15px">
              <a @click="djzhmm('注册账号')">注册账号</a>
              <a-divider type="vertical" />
              <a @click="djzhmm('找回密码')">找回密码</a>
            </div>
            <a-button type="primary" style="padding: 0 15px; margin-right: 15px"
              @click="zdzdetermine('确定登录')">登录</a-button>
            <a-button type="primary" style="padding: 0 15px; margin-left: 15px" @click="handleCancel">取消</a-button>
          </div>
        </span>
      </span>
      <span v-if="zdztitle == '注册'">
        <p style="text-align: center">根据你的身份选择注册</p>
        <!-- <div style="text-align: center">
          <a-button
            type="primary"
            style="width: 150px; height: 75px; background-color: orange; border: none; margin-right: 15px"
            @click="registerdetermine('供应商')"
            >供应商</a-button
          >
          <a-button
            type="primary"
            style="width: 150px; height: 75px; margin-left: 15px"
            @click="registerdetermine('评审专家')"
            >评审专家</a-button
          >
        </div> -->
        <div style="display: flex; justify-content: center; margin-bottom: 45px; margin-top: 30px">
          <div class="registrationselectionone" style="margin-right: 30px">
            <div style="margin-bottom: 15px; display: flex">
              <img src="@/assets/image/供应商.png" alt="" style="width: 100px" />
              <div style="width: 100%; margin: auto 0">供应商</div>
            </div>
            <p>用户描述：</p>
            <p>提供优质产品或服务的合作伙伴。</p>
            <div style="text-align: center">
              <a-button type="primary" style="width: 125px" @click="registerdetermine('供应商')">选择</a-button>
            </div>
          </div>
          <!-- <div class="registrationselectiontwo" style="margin-left: 30px">
            <div style="margin-bottom: 15px; display: flex">
              <img src="@/assets/image/评审专家.png" alt="" style="width: 100px" />
              <div style="width: 100%; margin: auto 0">评审专家</div>
            </div>
            <p>用户描述：</p>
            <p>具有专业知识和经验的评价者。</p>
            <div style="text-align: center">
              <a-button type="primary" style="width: 125px; background-color: orange; border: none"
                @click="registerdetermine('评审专家')">选择</a-button>
            </div>
          </div> -->
        </div>
      </span>
      <span v-if="zdztitle == '找回密码'">
        <a-form-model ref="formtonemm" :model="mmformState" :label-col="labelCol" :wrapper-col="wrapperCol"
          :rules="mmrules">
          <a-form-model-item label="邮箱" prop="mail"> <a-input v-model="mmformState.mail" />
          </a-form-model-item></a-form-model>
        <span>
          <div style="text-align: center">
            <a-button type="primary" style="padding: 0 15px; margin-right: 15px"
              @click="confirmretrieval('确定找回')">确定</a-button>
            <a-button type="primary" style="padding: 0 15px; margin-left: 15px" @click="returntoretrieve">取消</a-button>
          </div>
        </span>
      </span>
    </a-modal>
    <!-- 注册弹窗 -->
    <a-modal v-model:visible="zcvisible" :title="zctitle" :mask-closable="false" :footer="null" width="50%"
      @cancel="zchandleCancel">
      <!-- 注册时候 -->
      <span v-if="registeraction == '注册中'">
        <a-form-model :model="zcformState" :label-col="zclabelCol" :wrapper-col="zcwrapperCol" ref="formcheck"
          :rules="validatorRules">
          <a-form-model-item label="账号" prop="username">
            <a-input v-model="zcformState.username" />
          </a-form-model-item>
          <a-form-model-item label="密码" prop="password">
            <a-input-password v-model="zcformState.password" />
          </a-form-model-item>
          <a-form-model-item label="确认密码" prop="secondaryConfirmation">
            <a-input-password v-model="zcformState.secondaryConfirmation" />
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="mail">
            <a-input v-model="zcformState.mail" style="width: 60%" />
            <label style="color: red; margin-left: 15px">后续相关通知通过邮箱发送</label>
          </a-form-model-item>
          <a-form-model-item label="公司" prop="company" v-if="zctitle == '供应商注册-填写信息'">
            <a-input v-model="zcformState.company" />
          </a-form-model-item>
          <a-form-model-item label="营业执照" prop="businessLicense" v-if="zctitle == '供应商注册-填写信息'">
            <div class="clearfix">
              <a-upload name="multipartFile" accept=".jpg,.png,.jpeg" :multiple="false" :action="uploadImgUrl" list-type="picture-card"
                :file-list="fileImgList" :beforeUpload="beforeImgUpload" @preview="handlePreview('执照')"
                @change="tehandleImgChange">
                <div v-if="fileImgList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="PreviewhandleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
              <label style="color: red">请传上尺寸在800x800，大小在1M以内的图片</label>
            </div>
          </a-form-model-item>
          <a-form-model-item label="附件"  v-if="zctitle == '供应商注册-填写信息'">
            <div class="clearfix">
              <a-upload name="multipartFile" :multiple="false" :action="uploadFileUrl" :file-list="fileList"
                :beforeUpload="beforeUpload" @change="tehandleChange">
                <a-button v-if="fileList.length < 1"> <a-icon type="upload" /> 点击上传 </a-button>
              </a-upload>
              <label style="color: red">请上传大小在10M以内的文件</label>
            </div>
          </a-form-model-item>


          <a-form-model-item label="所属单位" prop="company" v-if="zctitle == '评审专家注册-填写信息'">
            <a-input v-model="zcformState.company" />
          </a-form-model-item>
          <a-form-model-item label="专业领域" prop="specialty" v-if="zctitle == '评审专家注册-填写信息'">
            <!-- <a-input v-model="zcformState.specialty" /> -->
            <!-- <j-dict-select-tag
              type="list"
              v-model="zcformState.specialty"
              dictCode="project_category"
              placeholder="请选择"
            /> -->
            <a-select v-model="zcformState.specialty" :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option v-for="d in areadata" :key="d.id" :value="d.id">{{ d.name }}</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="相关证书" prop="relevantCertificates" v-if="zctitle == '评审专家注册-填写信息'">
            <!-- <j-image-upload v-model="zcformState.relevantCertificates"></j-image-upload> -->
            <div class="clearfix">
              <a-upload name="multipartFile" :multiple="true" :action="uploadImgUrl" list-type="picture-card"
                :file-list="fileList" :beforeUpload="beforeUpload" @preview="handlePreview('证书')"
                @change="tehandleChangezsbook">
                <div v-if="fileList.length < 1">
                  <a-icon type="plus" />
                  <div class="ant-upload-text">上传</div>
                </div>
              </a-upload>
              <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="PreviewhandleCancel">
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
              <label style="color: red">请传上尺寸在800x800，大小在1M以内的图片</label>
            </div>
          </a-form-model-item>
          <a-form-model-item label="联系人" prop="contacts">
            <a-input v-model="zcformState.contacts" />
          </a-form-model-item>
          <a-form-model-item label="手机号" prop="phone">
            <a-input v-model="zcformState.phone" />
          </a-form-model-item>
          <a-form-model-item label="其他电话">
            <a-input v-model="zcformState.otherPhone" />
          </a-form-model-item>
        </a-form-model>
        <div style="text-align: center">
          <a-button type="primary" style="width: 75px" @click="confirmRegistration()">注册</a-button>
        </div>
      </span>
      <!-- 注册成功之后 -->
      <span v-if="registeraction == '注册成功'">
        <a-result status="success" title="注册成功" sub-title="">
          <template #extra>
            <a-button key="console" type="primary" style="width: 150px; height: 50px"
              @click="immediately()">立即登录</a-button>
            <!-- <a-button key="buy">关闭</a-button> -->
          </template>
        </a-result>
      </span>
    </a-modal>
  </div>
</template>

<script>
import listdetail from './announceListDetail.vue'
import thatperson from './thatPerson.vue'
import AnnounceApply from './AnnounceApply.vue'
import AnnounceDepositPayment from './AnnounceDepositPayment.vue'
import AnnounceFileDownload from './AnnounceFileDownload.vue'
import RefundApplicationForm from './RefundApplicationForm.vue'
import router from '../../router/index'
import backEndAddressUrl from '../../utils/backEndAddressUrl'

// import Vue from 'vue'
import { getAction, deleteAction, putAction, postAction, httpAction, getTeAction } from '@/api/index'

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}

export default {
  components: {
    listdetail,
    thatperson,
    AnnounceApply,
    AnnounceDepositPayment,
    AnnounceFileDownload,
    RefundApplicationForm,
  },
  data() {
    return {
      activeKey: '1',
      columns: [
        {
          title: '招标公告名称',
          dataIndex: 'title',
          // align: 'center',
          width: 400,
          ellipsis: true,
          customRender: (text) => {
            return <span style="font-weight: bold">{text}</span>
          },
        },
        {
          title: '状态',
          dataIndex: 'projectStatus_dictText',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'projectStatusSlot' },
        },
        {
          title: '采购类型',
          dataIndex: 'purchaseInformation_dictText',
          width: 100,
          align: 'center',
        },
        {
          title: '招标类型',
          dataIndex: 'type_dictText',
          width: 100,
          align: 'center',
        },
        {
          title: '有无补充公告',
          dataIndex: 'supplement',
          width: 120,
          align: 'center',
          scopedSlots: { customRender: 'supplementSlot' },
        },
        {
          title: '报名时间',
          dataIndex: 'registrationValidityTime',
          width: 210,
          align: 'center',
        },
        {
          title: '开标时间',
          dataIndex: 'bidOpeningTime',
          width: 170,
          align: 'center',
        },
        {
          title: '投标截止时间',
          dataIndex: 'bidDeadline',
          width: 170,
          align: 'center',
          customRender: (text, record) => {
            return {
              children: text,
              attrs: {
                title: `缴费截止时间: ${text}`,
              },
            }
          },
        },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 170,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 207,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnsCopyOne: [
        {
          title: '招标公告名称',
          dataIndex: 'title',
          // align: 'center',
          width: 600,
          ellipsis: true,
          customRender: (text) => {
            return <span style="font-weight: bold">{text}</span>
          },
        },
        {
          title: '采购类型',
          dataIndex: 'purchaseInformation_dictText',
          // width: 180,
          align: 'center',
        },
        {
          title: '招标类型',
          dataIndex: 'type_dictText',
          align: 'center',
        },
        // {
        //   title: '是否有补充公告',
        //   dataIndex: 'supplement',
        //   width: 180,
        //   align: 'center',
        //   scopedSlots: { customRender: 'supplementSlot' },
        // },
        // {
        //   title: '报名时间',
        //   dataIndex: 'registrationValidityTime',
        //   width: 190,
        //   align: 'center',
        // },
        // {
        //   title: '开标时间',
        //   dataIndex: 'bidOpeningTime',
        //   width: 190,
        //   align: 'center',
        // },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 190,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 147,
          // fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnsCopyTwo: [
        {
          title: '招标公告名称',
          dataIndex: 'title',
          // align: 'center',
          width: 500,
          ellipsis: true,
          customRender: (text) => {
            return <span style="font-weight: bold">{text}</span>
          },
        },
        {
          title: '采购类型',
          dataIndex: 'purchaseInformation_dictText',
          // width: 180,
          align: 'center',
        },
        {
          title: '招标类型',
          dataIndex: 'type_dictText',
          align: 'center',
        },
        {
          title: '是否有补充公告',
          dataIndex: 'supplement',
          width: 180,
          align: 'center',
          scopedSlots: { customRender: 'supplementSlot' },
        },
        // {
        //   title: '报名时间',
        //   dataIndex: 'registrationValidityTime',
        //   width: 190,
        //   align: 'center',
        // },
        // {
        //   title: '开标时间',
        //   dataIndex: 'bidOpeningTime',
        //   width: 190,
        //   align: 'center',
        // },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 190,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 147,
          // fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataSource: [
        // {
        //   id: '0',
        //   name: '福建省气象风险普查系统',
        //   age: '政府采购',
        //   addresstype: '招标公示',
        //   address: '2023-06-25',
        // },
      ],
      loading: false,
      ipagination: {
        current: 1,
        pageSize: 30,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        // showQuickJumper: true,
        // showSizeChanger: true,
        total: 0,
      },
      /* table选中keys*/
      //   selectedRowKeys: [],
      /* table选中records*/
      //   selectionRows: [],
      valueone: '',
      valuetwo: '',
      valuetherr: '',
      viewpage: '查看列表页',
      zdzvisible: false,
      zdztitle: '',
      // 登录等弹窗
      labelCol: { span: 5 },
      wrapperCol: { span: 16 },
      formState: {
        username: '', //账号
        password: '', //密码
      },
      // 注册弹窗
      zcvisible: false,
      zctitle: '',
      zclabelCol: { span: 5 },
      zcwrapperCol: { span: 16 },
      zcformState: {
        username: '', //账号
        password: '', //密码
        secondaryConfirmation: '', //二次确认
        mail: '', //邮箱
        company: '', //公司or所属单位
        // affiliatedUnit: '', //所属单位
        specialty: '', //专业领域
        businessLicense: '', //营业执照
        files:'',//附件
        relevantCertificates: '', //相关证书
        contacts: '', //联系人
        phone: '', //手机号
        otherPhone: '', //其他电话
        roleType: '', //角色类别
      },
      // 接口
      url: {
        list: '/project/bidAnnouncement/list',
        // listmy: '/project/bidAnnouncement/listByBid',
        listlogin: '/project/bidAnnouncement/listLogin',
        purchasingMethodUrl: "/project/tenderProject/getPurchaseInformation",
        register: '/user/zongshiUser/add', //用户注册-post
        logon: '/user/zongshiUser/login', //用户登录-post
        logout: '/user/zongshiUser/logout', //用户登出-get
        getip: '/file/getIP', //获取本机ip-get
        getretrievepassword: '/user/zongshiUser/queryByMail', //通过邮箱找回密码-get
      },
      userInformation: {},
      registeraction: '注册中',
      // 图片上传相关
      fileList: [],
      fileImgList: [],
      previewVisible: false,
      previewImage: '',
      uploadImgUrl: '',
      uploadFileUrl: '',
      uploadBlocked: false,
      validatorRules: {
        username: [{ required: true, message: '请输入账号！', trigger: 'blur' }],
        password: [
          {
            required: true,
            pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
            message: '密码至少八位数且由英文和数字组成!',
          },
        ],
        secondaryConfirmation: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: this.validatePasswordConfirmation, trigger: 'blur' },
        ],
        company: [{ required: true, message: '请输入单位名称！', trigger: 'blur' }],
        mail: [
          { required: true, message: '请输入邮箱！', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: '请填写正确的邮箱!' },
        ],
        contacts: [{ required: true, message: '请输入联系人名称！', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号！', trigger: 'blur' },
          { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '请填写正确的手机号码!' },
        ],
        relevantCertificates: [{ required: true, message: '请上传相关证书！', trigger: 'blur' }],
        specialty: [{ required: true, message: '请选择专业领域！', trigger: 'blur' }],
        businessLicense: [{ required: true, message: '请上传营业执照！', trigger: 'blur' }],
        files: [{ required: true, message: '请上传营业执照！', trigger: 'blur' }],
        // xxx: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      validatorRulestwo: {
        username: [{ required: true, message: '请输入账号！', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码！', trigger: 'blur' }],
      },
      vague: '',
      mmformState: {
        mail: '',
      },
      mmrules: {
        mail: [
          { required: true, message: '请输入邮箱！', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: '请填写正确的邮箱!' },
        ],
      },
      areadata: [],
      // tstuploadurl: window._CONFIG['domianURL']+"/sys/common/upload",
      options: [
        { id: 1, text: '自行采购' },
        { id: 2, text: '中直' },
        { id: 3, text: '政府采购' },
        { id: 4, text: '部队' },
        { id: 5, text: '网上竞价' },
      ],
    }
  },
  created() {
    // console.log(backEndAddressUrl.backEndUrl)
    const storedData = sessionStorage.getItem('txtuserData')
    if (storedData) {
      // 将存储的JSON字符串转换为对象
      // console.log(storedData)
      console.log('有登录信息')
      this.userInformation = JSON.parse(storedData)
      // 在Vuex中或组件的data中保存提取出的数据
      // 例如：this.$store.commit('setUserData', userData);
      // 或：this.userData = userData;
    } else {
      console.log('暂无登录信息')
      this.userInformation = {
        username: '',
        token: '',
        specialty: '',
        roleType: '',
        relevantCertificates: '',
        phone: '',
        otherPhone: '',
        oldPassword: '',
        newPassword: '',
        mail: '',
        id: '',
        contacts: '',
        company: '',
        businessLicense: '',
      }
    }
    // 获取ip地址
    // getAction(this.url.getip).then((res) => {
    //   console.log(res)
    //   if (res.code == 200) {
    //     // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
    //     // this.zcformState.relevantCertificates = 'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
    //     this.uploadImgUrl = 'http://' + res.result + '/jeecg-boot/file/save'
    //   } else {
    //     // this.$message.warning('获取IP地址失败')
    //     console.log('获取ip地址失败')
    //   }
    // })
    this.uploadImgUrl = backEndAddressUrl.backEndUrl + '/file/saveImg' //上传图片
    this.uploadFileUrl = backEndAddressUrl.backEndUrl + '/file/saveFile'//上传文件
    this.getQueryPurchasingFun()
    this.getNoticeList()

  },
  computed: {},
  methods: {
    isWithinTimeRange(record) {
      // console.log('测试111', record)
      const [startDateStr, endDateStr] = record.registrationValidityTime.split('至')
      const startDate = new Date(startDateStr)
      const endDate = new Date(endDateStr)
      const currentTime = new Date()
      // currentTime.setHours(0, 0, 0, 0)
      // startDate.setHours(0, 0, 0, 0)
      // endDate.setHours(0, 0, 0, 0)
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 59, 999)
      // console.log(startDate,endDate)
      // console.log(currentTime >= startDate && currentTime <= endDate)
      const testtime = currentTime >= startDate && currentTime <= endDate
      return testtime
    },
    // ...mapActions('users', ['testloginUser']),
    // 后台路由跳转
    // tzht() {
    //   // console.log('login')
    //   // console.log(router)
    //   console.log(Vue.ls.get(ACCESS_TOKEN))
    //   // if (router.history.current.query.redirect == '/') {
    //   //   console.log('1')
    //   //   this.$router.replace({ path: '/user/login' })
    //   // } else if(router.history.current.query.redirect == '/dashboard/analysis') {
    //   //   console.log('2')
    //   //   this.$router.replace({ path: '/dashboard/analysis' })
    //   // }
    //   if (Vue.ls.get(ACCESS_TOKEN) != null && Vue.ls.get(ACCESS_TOKEN) != undefined && Vue.ls.get(ACCESS_TOKEN) != '') {
    //     // this.$router.replace({ path: '/dashboard/analysis',replace: true})
    //     // this.$router.push('/dashboard/analysis')
    //     this.$router.push({ path: '/dashboard/analysis', replace: true }).catch(() => {
    //       console.log('跳转首页出错,这个错误从哪里来的')
    //     })
    //   } else {
    //     this.$router.replace({ path: '/user/login' })
    //   }
    //   // this.$router.replace({ path: '/user/login' })
    // },
    // 查询项目公告列表
    getNoticeList() {
      console.log(this.userInformation)
      this.loading = true
      console.log('查询公告列表')
      let ggtype = ''
      if (this.activeKey == '0') {
      } else {
        ggtype = this.activeKey
      }
      if (
        this.userInformation.username != '' &&
        this.userInformation.username != null &&
        this.userInformation.username != undefined
      ) {
        const customHeaders = {
          // 在这里设置请求头，例如Authorization等
          Authorization: this.userInformation.token,
        }
        getTeAction(
          this.url.listlogin +
          '?type=' +
          ggtype +
          '&vague=' +
          this.valueone +
          '&purchaseInformation=' +
          this.valuetwo +
          '&announcementState=' +
          1 +
          '&projectStatus=' +
          this.valuetherr +
          // '&my=' +
          // this.userInformation.username +
          '&pageNo=' +
          this.ipagination.current +
          '&pageSize=' +
          this.ipagination.pageSize,
          {},
          customHeaders
        ).then((ress) => {
          console.log(ress)
          this.ipagination.total = ress.result.total
          this.dataSource = ress.result.records
          this.loading = false
        })
      } else {
        getAction(
          this.url.list +
          '?type=' +
          ggtype +
          '&vague=' +
          this.valueone +
          '&purchaseInformation=' +
          this.valuetwo +
          '&projectStatus=' +
          this.valuetherr +
          '&announcementState=' +
          1 +
          // '&my=' +
          // this.userInformation.username +
          '&pageNo=' +
          this.ipagination.current +
          '&pageSize=' +
          this.ipagination.pageSize
        ).then((res) => {
          console.log('调用第一次', res)
          if (res.code == 200) {
            // console.log('查询成功')
            this.ipagination.total = res.result.total
            this.dataSource = res.result.records
            this.loading = false
            // if (
            //   this.userInformation.username != '' &&
            //   this.userInformation.username != null &&
            //   this.userInformation.username != undefined
            // ) {
            //   const tstdataSource = res.result.records
            //   const customHeaders = {
            //     // 在这里设置请求头，例如Authorization等
            //     Authorization: this.userInformation.token,
            //   }
            //   getTeAction(
            //     this.url.listmy +
            //       '?type=' +
            //       ggtype +
            //       '&vague=' +
            //       this.valueone +
            //       '&purchaseInformation=' +
            //       this.valuetwo +
            //       '&announcementState=' +
            //       1 +
            //       '&my=' +
            //       this.userInformation.username +
            //       '&pageNo=' +
            //       this.ipagination.current +
            //       '&pageSize=' +
            //       this.ipagination.pageSize,
            //     {},
            //     customHeaders
            //   ).then((ress) => {
            //     console.log('调用第二次', ress)
            //     if (ress.code == 200) {
            //       for (let i = 0; i < ress.result.records.length; i++) {
            //         var index = tstdataSource.findIndex((item) => item.id === ress.result.records[i].id)
            //         if (index != -1) {
            //           tstdataSource[index].registrationStatus = ress.result.records[i].registrationStatus
            //         } else {
            //           console.log('没有报名项目')
            //         }
            //       }
            //       this.dataSource = tstdataSource
            //       console.log('匹配完成', this.dataSource)
            //       this.loading = false
            //     } else {
            //       this.$message.warning('根据当前用户查询报名状态失败')
            //       this.loading = false
            //     }
            //   })
            // } else {
            //   this.dataSource = res.result.records
            //   this.loading = false
            // }
          } else {
            // console.log('查询失败')
            this.loading = false
          }
        })
      }
    },
    getQueryPurchasingFun() {
      getAction(this.url.purchasingMethodUrl, '').then((ress) => {
        console.log(ress.result)
        this.options = ress.result
      })
    },
    handleTabChange() {
      console.log('点击cars', this.activeKey)
      this.loading = true
      this.dataSource = []
      setTimeout(() => {
        this.ipagination.pageSize = 30
        this.ipagination.current = 1
        this.resetSearch()
        // this.getNoticeList()
      }, 1000) // 1000 毫秒延迟
    },
    handleTableChange(pagination, filters, sorter) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      console.log('点击分页变化', pagination)
      this.ipagination.pageSize = pagination.pageSize
      this.ipagination.current = pagination.current
      this.getNoticeList()
      //   if (Object.keys(sorter).length > 0) {
      //     this.isorter.column = sorter.field;
      //     this.isorter.order = "ascend" == sorter.order ? "asc" : "desc"
      //   }
      //   this.ipagination = pagination;
    },
    // onSelectChange(selectedRowKeys, selectionRows) {
    //   this.selectedRowKeys = selectedRowKeys;
    //   this.selectionRows = selectionRows;
    // },
    searchQuery() {
      console.log('回车搜索')
      this.ipagination.pageSize = 30
      this.ipagination.current = 1
      this.querySearch()
    },
    focus() {
      console.log('focus')
    },
    handleChange(value) {
      console.log(value)
    },
    // 查询搜索
    querySearch() {
      console.log('点击查询')
      this.ipagination.pageSize = 30
      this.ipagination.current = 1
      this.getNoticeList()
    },
    // 重置搜索
    resetSearch() {
      console.log('重置')
      this.valueone = ''
      this.valuetwo = ''
      this.valuetherr = ''
      this.ipagination.pageSize = 30
      this.ipagination.current = 1
      this.getNoticeList()
    },
    // 详情
    seeDetails(textaction, record) {
      console.log('点击详情', textaction, record)
      this.$nextTick(() => {
        this.$refs.listdetailref.obtainDetails(record, textaction)
        this.viewpage = '查看详情页'
      })
    },
    // 处理子组件传递回来数据
    handleChildEvent(transferData, row) {
      console.log('子组件调用', transferData)
      if (transferData == '调用登录') {
        // console.log('调用登录')
        this.zdaction('登录')
      } else if (transferData == '调用缴费') {
        this.payFeesFun(row, this.userInformation)
      } else if (transferData == '调用报名') {
        this.ApplyMoadlFun(row)
      } else if (transferData == '报名里的缴费') {
        console.log(this.viewpage)
        if (this.viewpage == '查看详情页') {
          this.$refs.listdetailref.getuser()
        } else {
          this.getNoticeList()
        }


      } else {
        const storedData = sessionStorage.getItem('txtuserData')
        if (storedData) {
          console.log('有登录信息')
          this.userInformation = JSON.parse(storedData)
          // console.log(this.userInformation)
        } else {
          console.log('暂无登录信息')
        }
        this.getNoticeList()
        this.viewpage = transferData
      }
    },
    // 注册、登录、找回密码弹窗方法
    zdaction(txtaction) {
      console.log('当前执行方法：', txtaction)
      // 置空标题
      this.zdztitle = ''
      // 根据执行方法清空对应表单
      if (txtaction == '登录') {
        this.formState = {
          username: '', //账号
          password: '', //密码
        }
      }
      // 标题重新赋值
      this.zdztitle = txtaction
      this.zdzvisible = true
    },
    //回到首页
    goHomePage(){
      this.viewpage = '查看列表页'
    },
    handleCancel() {
      this.zdzvisible = false
      if (this.zdztitle == '登录') {
        this.$refs.formtone.resetFields()
      } else if (this.zdztitle == '找回密码') {
        this.$refs.formtonemm.resetFields()
      }
    },
    // 确定注册、登录、找回密码
    zdzdetermine(txtaction) {
      console.log('当前确定操作：', txtaction)
      this.$refs.formtone.validate((valid) => {
        if (valid) {
          postAction(this.url.logon, this.formState).then((res) => {
            console.log('登录', res)
            if (res.code == 200) {
              // 登录返回拆解用户信息
              const startIndex = res.result.indexOf('(')
              const endIndex = res.result.indexOf(')')
              const contentInsideParentheses = res.result.substring(startIndex + 1, endIndex)
              // console.log('拆解成字符串',contentInsideParentheses)
              // 字符串转换成对象
              const keyValuePairs = contentInsideParentheses.split(', ')
              const resultObject = {}
              // console.log("keyValuePairs",keyValuePairs)
              keyValuePairs.forEach((keyValuePair) => {

                const [key, value] = keyValuePair.split('=')
                // console.log(value.trim())
                const trimmedKey = key.trim()
                const trimmedValue = value.trim()==='null'? null : value.trim()
                resultObject[trimmedKey] = trimmedValue
              })
              // console.log('字符串转换成对象',resultObject)
              // 拆解token
              const tokenMatch = res.result.match(/token:(.*)/)
              console.log(tokenMatch)
              if (tokenMatch && tokenMatch[1]) {
                // resultObject[token] = tokenMatch[1]
                this.$set(resultObject, 'token', tokenMatch[1])
              } else {
                console.log('无法拆解出token')
              }
              console.log('存放token', resultObject)
              resultObject['specialtyList'] = resultObject.specialtyId.split(',')
              // 将拆解出来的用户信息存放进vuex
              this.userInformation = resultObject
              // this.$store.dispatch('testloginUser', resultObject)
              // this.testloginUser(resultObject)
              // 将数据存储到sessionStorage
              sessionStorage.setItem('txtuserData', JSON.stringify(resultObject))

              // this.$refs.formtonemm.resetFields()
              this.zdzvisible = false
              this.$message.success('登录成功')
              this.$refs.formtone.resetFields()
              console.log(this.viewpage)
              if (this.viewpage == '查看列表页') {
                this.getNoticeList()
              } else if (this.viewpage == '查看详情页') {
                this.$nextTick(() => {
                  this.$refs.listdetailref.getuser()
                  // this.viewpage = '查看详情页'
                })
              }
            } else {
              this.$message.warning(res.message)
            }
          })
          // this.$store.dispatch('testloginUser', this.formState)
          // console.log('测试是否存储成功', this.$store.state.testUser)
          // this.zdzvisible = false
        } else {
        }
      })
    },
    // 判断注册哪个身份
    registerdetermine(txtidentity) {
      console.log('需要注册的身份：', txtidentity)
      this.zctitle = ''
      this.zctitle = txtidentity + '注册-填写信息'
      this.zcformState = {
        username: '',
        password: '',
        secondaryConfirmation: '',
        mail: '',
        company: '',
        specialty: '',
        businessLicense: '',
        relevantCertificates: '',
        contacts: '',
        phone: '',
        otherPhone: '',
        roleType: '',
      }
      this.registeraction = '注册中'
      this.fileList = []
      this.fileImgList=[]
      this.previewVisible = false
      this.previewImage = ''
      this.zcvisible = true
      // this.zdzvisible = false
      this.handleCancel()
      if (txtidentity == '供应商') {
        this.zcformState.roleType = 1
      } else if (txtidentity == '评审专家') {
        this.zcformState.roleType = 2
        this.initDictConfig()
      }
    },
    // 关闭注册
    zchandleCancel() {
      this.zcvisible = false
      // this.registeraction = '注册成功'
      if (this.registeraction == '注册中') {
        this.$refs.formcheck.resetFields()
      }
    },
    // 校验密码
    validatePasswordConfirmation(rule, value, callback) {
      if (value === this.zcformState.password) {
        callback() // 校验通过
      } else {
        callback(new Error('确认密码与密码不一致')) // 校验失败
      }
    },
    // 确定注册
    confirmRegistration() {
      console.log('确定注册：', this.zcformState)
      // console.log(this.$refs.formcheck)
      let that = this
      this.$refs.formcheck.validate((valid) => {
        if (valid) {
          postAction(this.url.register, this.zcformState).then((res) => {
            console.log('注册', res)
            if (res.code == 200) {
              // this.zdztitle = '登录'
              // this.$message.success('注册成功')
              // this.zcvisible = false
              this.registeraction = '注册成功'
              that.$refs.formcheck.resetFields()
            } else {
              if (res.message == '该用户已存在' || res.message == '该用户所使用的邮箱已存在') {
                this.$message.warning(res.message)
              } else {
                // this.$message.warning('注册信息填写有误，请检查注册信息')
                this.$message.warning('出现未知问题，请稍后重试！')
              }
            }
          })
        } else {
        }
      })
    },
    // 查看个人中心
    seethatperson() {
      console.log('查看个人中心', this.userInformation)
      this.$nextTick(() => {
        // this.$refs.listdetailref.obtainDetails(this.userInformation)
        this.viewpage = '查看个人中心'
      })
    },
    // 用户登出
    Logout() {
      console.log('退出登录')
      const customHeaders = {
        // 在这里设置请求头，例如Authorization等
        Authorization: this.userInformation.token,
      }
      getTeAction(this.url.logout, {}, customHeaders).then((res) => {
        console.log(res)
        if (res.code == 200) {
          sessionStorage.removeItem('txtuserData')
          this.userInformation = {}
          if (this.viewpage == '查看个人中心') {
            this.getNoticeList()
            this.viewpage = '查看列表页'
          } else if (this.viewpage == '查看详情页') {
            this.$nextTick(() => {
              this.$refs.listdetailref.getuser()
              // this.viewpage = '查看详情页'
            })
          } else {
            this.getNoticeList()
          }
          this.$message.success('退出成功')
        } else {
          this.$message.warning('退出失败')
        }
      })
      // 点击退出时候可以使用的办法
    },
    // 立即登录
    immediately() {
      this.zcvisible = false
      this.zdztitle = '登录'
      this.zdzvisible = true
    },
    // 图片部分
    // handleCancel() {
    //   this.previewVisible = false
    // },
    handlePreview(file) {
      // console.log(file.url, file.preview, file)
      // // if (!file.url && !file.preview) {
      // //   file.preview = getBase64(file.originFileObj)
      // // }
      // file.preview = getBase64(file.originFileObj)
      // this.previewImage = file.url || file.preview
      // this.previewVisible = true
      console.log('点击查看图片', file)
      // this.previewImage.push({url: this.zcformState.businessLicense})
      if (file == '执照') {
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.zcformState.businessLicense
      } else if (file == '证书') {
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.zcformState.relevantCertificates
      }
      this.previewVisible = true
    },
    fileDownloadBtnFun(data, Name) {
      console.log("文件下载")
      const dotIndex = Name.lastIndexOf(".");
      var fileNameNO
      if (dotIndex !== -1) {
        fileNameNO = Name.slice(dotIndex + 1);
      } else {
      }
      if (!data || data.size === 0) {
        Vue.prototype['$message'].warning('文件下载失败')
        return
      }
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(new Blob([data]), "附件." + fileNameNO)
      } else {
        let url = window.URL.createObjectURL(new Blob([data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', "附件." + fileNameNO)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) //下载完成移除元素
        window.URL.revokeObjectURL(url) //释放掉blob对象
      }
    },
     // 执照
    tehandleImgChange({ fileList }) {
      // console.log(fileList, fileList.length)
      // console.log('1111',this.uploadBlocked,fileList)
      if (this.uploadBlocked == false) {
        this.fileImgList = fileList
      }
      if (fileList.length != 0) {
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            // getAction(this.url.getip).then((res) => {
            //   console.log(res)
            //   if (res.code == 200) {
            // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
            // this.zcformState.businessLicense =
            //   'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
            // this.zcformState.businessLicense = window._CONFIG['domianURL'] + '/file/imgUrl/' + response.message
            this.zcformState.businessLicense = response.message
            //   } else {
            //     this.$message.warning('获取IP地址失败')
            //   }
            // })
          } else {
            console.log('上传失败:', response.message)
            this.fileImgList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileImgList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.fileImgList = []
        this.zcformState.businessLicense = ''
      }
    },
   // 附件
    tehandleChange({ fileList }) {
      // console.log(fileList, fileList.length)
      // console.log('1111',this.uploadBlocked,fileList)
      if (this.uploadBlocked == false) {
        this.fileList = fileList
      }
      if (fileList.length != 0) {
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            // getAction(this.url.getip).then((res) => {
            //   console.log(res)
            //   if (res.code == 200) {
            // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
            // this.zcformState.businessLicense =
            //   'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
            // this.zcformState.businessLicense = window._CONFIG['domianURL'] + '/file/imgUrl/' + response.message
            this.zcformState.files = response.message
            //   } else {
            //     this.$message.warning('获取IP地址失败')
            //   }
            // })
          } else {
            console.log('上传失败:', response.message)
            this.fileList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.fileList = []
        this.zcformState.files = ''
      }
    },
    // 证书
    tehandleChangezsbook({ fileList }) {
      if (this.uploadBlocked == false) {
        this.fileList = fileList
      }
      if (fileList.length != 0) {
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            // getAction(this.url.getip).then((res) => {
            //   console.log(res)
            //   if (res.code == 200) {
            // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
            // this.zcformState.relevantCertificates =
            //   'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
            // this.zcformState.relevantCertificates = window._CONFIG['domianURL'] + '/file/imgUrl/' + response.message
            this.zcformState.relevantCertificates = response.message
            //   } else {
            //     this.$message.warning('获取IP地址失败')
            //   }
            // })
          } else {
            console.log('上传失败:', response.message)
            this.fileList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.fileList = []
        this.zcformState.relevantCertificates = ''
      }
    },
    // 关闭预览
    PreviewhandleCancel() {
      this.previewVisible = false
    },
    beforeUpload(file) {
      console.log(file)
      this.uploadBlocked = false
      if (this.zctitle == '评审专家注册-填写信息') {
        var isLt1M = file.size / 1024 / 1024 < 1 // 限制文件大小为 10MB
      } else {
        var isLt1M = file.size / 1024 / 1024 < 10 // 限制文件大小为 10MB
      }
      console.log(isLt1M)
      let image = new Image()
      if (!isLt1M) {
        // this.$message.error('文件大小不能超过 10MB！')
        if (this.zctitle == '评审专家注册-填写信息') {
          // var isLt1M = file.size / 1024 / 1024 < 1 // 限制文件大小为 10MB
          this.$message.error('文件大小不能超过 1MB！')
        } else {
          // var isLt1M = file.size / 1024 / 1024 < 10 // 限制文件大小为 10MB
          this.$message.error('文件大小不能超过 10MB！')
        }
        this.fileList = []
        this.csqk()
        this.uploadBlocked = true
        return false
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        image.src = e.target.result
        image.onload = () => {
          // if (image.width <= 800 && image.height <= 800) {
          //   this.$message.success('图片尺寸和大小符合要求！')
          //   this.uploadBlocked = true
          // } else {
          //   this.$message.error('图片尺寸必须在 800x800 以内！')
          //   this.fileList = []
          //   this.uploadBlocked = true
          // }
        }
      }
      reader.readAsDataURL(file)
      this.uploadBlocked = false
      return true // 允许上传
    },
    beforeImgUpload(file) {
      this.uploadBlocked = false
      const isLt1M = file.size / 1024 / 1024 < 1 // 限制文件大小为 1MB
      let image = new Image()
      if (!isLt1M) {
        this.$message.error('图片大小不能超过 1MB！')
        this.fileImgList = []
        this.csqk()
        this.uploadBlocked = true
        return false
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        image.src = e.target.result
        image.onload = () => {
          if (image.width <= 800 && image.height <= 800) {
            this.$message.success('图片尺寸和大小符合要求！')
            this.uploadBlocked = true
          } else {
            this.$message.error('图片尺寸必须在 800x800 以内！')
            this.fileImgList = []
            this.uploadBlocked = true
          }
        }
      }
      reader.readAsDataURL(file)
      this.uploadBlocked = false
      return true // 允许上传
    },
    csqk() {
      // console.log('测试清空',this.fileList)
    },
    ApplyMoadlFun(row) {
      const [startDateStr, endDateStr] = row.registrationValidityTime.split('至')
      const startDate = new Date(startDateStr)
      const endDate = new Date(endDateStr)
      const currentTime = new Date()
      // console.log(startDate,endDate,currentTime)
      // currentTime.setHours(0, 0, 0, 0)
      startDate.setHours(0, 0, 0, 0)
      endDate.setHours(23, 59, 59, 999)
      console.log(startDate, endDate, currentTime)
      // console.log(currentTime >= startDate && currentTime <= endDate)
      const testtime = currentTime >= startDate && currentTime <= endDate
      if (this.userInformation.id == '' || this.userInformation.id == undefined || this.userInformation.id == null) {
        this.zdaction('登录')
        return this.$message.warning('请登录后在进行报名')
      }
      if (testtime == true) {
        console.log('在报名时间区间内')
        if (
          row.type == 1 &&
          (row.registrationStatus == 1 || row.registrationStatus == null) &&
          row.projectStatus != 5
        ) {
          this.$refs.modalForm.edit(row, this.userInformation)
        } else {
          this.$message.warning('非法操作')
        }
      } else {
        console.log('不在报名时间区间内')
        this.$message.warning('不在报名时间内')
      }
    },
    modalFormOk() {
      console.log('报名成功modalFormOk')
      if (this.viewpage == '查看列表页') {
        this.getNoticeList()
      } else if (this.viewpage == '查看详情页') {
        this.$nextTick(() => {
          this.$refs.listdetailref.getuser()
          // this.viewpage = '查看详情页'
        })
      }
    },
    djzhmm(txtaction) {
      console.log(txtaction)
      if (txtaction == '找回密码') {
        this.$refs.formtone.resetFields()
        this.mmformState = {
          mail: '',
        }
        this.zdztitle = txtaction
      } else {
        this.zdaction('注册')
      }
    },
    // 确定找回
    confirmretrieval() {
      this.$refs.formtonemm.validate((valid) => {
        if (valid) {
          const customHeaders = {
            // 在这里设置请求头，例如Authorization等
            Authorization: this.userInformation.token,
          }
          // getTeAction(this.url.getretrievepassword, this.mmformState, customHeaders).then((res) => {
          //   console.log(res)
          // })
          getAction(this.url.getretrievepassword, this.mmformState).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message.success(res.message + '，请注意邮箱信息！')
              this.handleCancel()
            } else {
              this.$message.warning('未找到使用此邮箱用户，该邮箱可能还未注册')
            }
          })
        } else {
        }
      })
    },
    // 返回登录
    returntoretrieve() {
      this.$refs.formtonemm.resetFields()
      this.formState = {
        username: '', //账号
        password: '', //密码
      }
      this.zdztitle = '登录'
    },
    // 查询专业领域
    initDictConfig() {
      this.areadata = []
      getAction('/user/expertSpecialty/list' + '?pageNo=' + 1 + '&pageSize=' + 999).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.areadata = res.result.records
        } else {
          this.areadata = []
        }
      })
    },
    // 缴费
    payFeesFun(row) {
      console.log(row)
      console.log('缴费截至时间', row.bidDeadline)
      const startDate = new Date(row.bidDeadline)
      // startDate.setHours(23, 59, 59, 999)
      console.log('转换的截至时间', startDate)
      const currentTime = new Date()
      console.log('获取当前时间', currentTime)
      // currentTime.setHours(0, 0, 0, 0)
      // startDate.setHours(0, 0, 0, 0)
      console.log('判断', currentTime > startDate)
      if (currentTime > startDate == true) {
        console.log('当前时间大于截至时间')
        return this.$message.warning('缴费已截止')
      } else {
        console.log('当前时间小于或等于截至时间')
        this.$refs.DepositPayment.edit(row, this.userInformation)
      }
    },
    refreshList() {
      debugger;
      console.log('缴费成功', this.viewpage)
      debugger;
      console.log(this.viewpage)
      if (this.viewpage == '查看列表页') {
        this.getNoticeList()
      } else if (this.viewpage == '查看详情页') {
        this.$refs.listdetailref.getuser()
      }
    },
    // 下载标书
    downloadTenderFun(row) {
      this.$refs.announceFileDownload.edit(row, this.userInformation)
    },
    // 申请退还保证金
    reimbursement(Anid) {
      this.$refs.drawback.openARefund(Anid, this.userInformation.token)
    },
    // 跳转补充
    jumpToSupplement(textaction, record) {
      console.log('跳转到补充公告')
      this.$nextTick(() => {
        this.$refs.listdetailref.obtainDetails(record, textaction)
        this.viewpage = '查看详情页'
      })
    },
  },
}
</script>

<style scoped>
.announce_header {
  width: 100%;
  height: 150px;
  /* background-color: pink; */
  display: flex;
  background: #0f0c29;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #24243e, #302b63, #0f0c29);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #24243e, #302b63, #0f0c29);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
  position: fixed;
  top: 0;
  z-index: 9;
  border-bottom: 5px double #fff;
}

.announce_header_left {
  width: 70%;
  height: 100%;
  /* background-color: red; */
  padding-left: 45px;
}

.announce_header_left_h1 {
  margin-bottom: 0px !important;
  color: white;
  /* line-height: 100px; */
  margin-top: 20px;
  font-family: 楷体;
  font-size: 55px;
}

.announce_header_right {
  width: 30%;
  height: 150px;
  position: relative;
  /* background-color: blue; */
}

.announce_header_right_btnone {
  text-align: right;
  display: contents;
  position: absolute;
  width: 100%;
  right: 20px;
  top: 20px;
  display: inline;
  /* margin-top: 55px;
  margin-right: 45px; */
}

/* .announce_header_right_btnone .announce_header_right_btnonetst{
  
} */
.midland_one {
  width: 100%;
  display: flex;
}

.midland_one_left {
  width: 70%;
  height: 66px;
  padding-left: 45px;
  line-height: 66px;
  font-size: 16px;
}

.midland_one_right {
  width: 30%;
  height: 66px;
  text-align: right;
  line-height: 63px;
}

.midland_three {
  padding: 0 30px;
}

.registrationselectionone,
.registrationselectiontwo {
  width: 200px;
  padding: 15px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  /* 横向偏移量 | 纵向偏移量 | 模糊半径 | 阴影颜色 */
}

.inactive {
  color: white !important;
  background-color: #c1c1c1 !important;
  border: #c1c1c1 !important;
}
</style>
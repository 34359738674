// import axios from 'axios'
// import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import backEndAddressUrl from '../utils/backEndAddressUrl'
import { Modal,message } from 'ant-design-vue';
 
// 创建axios的对象
const instance = axios.create({
    baseURL: backEndAddressUrl.backEndUrl,  //配置固定域名
    timeout: 20000
})
 
// 请求拦截
// 所有的网络请求都会走这个方法,可以在请求添加自定义内容
instance.interceptors.request.use(
    function (config) {
        // config.headers['Authorization'] = '123456' // 请求头添加token值
        // config.headers.info = 'lxy'  //请求头添加info值
        // console.log("request",config)
        return config
    },
    function (err) {
        // console.log("requesErr",err)
        return err
    }
)
 
// 响应拦截
// 此处可以根据服务器返回的状态码做相应的数据
instance.interceptors.response.use(
    function (response) {
        const res = response
        // console.log("response",response)
        if (res.status === 500) {
            // MessageBox.alert('系统未登录，请重新登录', '错误', {
            //     confirmButtonText: '确定',
            //     type: 'error'
            // }).then(() => {
            //     store.dispatch('FedLogOut').then(() => {
            //         location.reload()
            //     })
            // })
            Modal.error({
                title: '请求错误',
                content: '系统内部错误，请联系管理员维护',
              });
            return Promise.reject('error')
        }else{
            return response.data
        }
        //  else if (res.errno === 502) {
        //     MessageBox.alert('系统内部错误，请联系管理员维护', '错误', {
        //         confirmButtonText: '确定',
        //         type: 'error'
        //     })
        //     return Promise.reject('error')
        // }
    },
    function (err) {
        // debugger;
        // console.log("responseErr",err)
        switch (err.request.status) {
            case 401:
                // Modal.error({
                //     title: '请求错误',
                //     content: '系统内部错误，请联系管理员维护',
                //   });
                message.error('登录过期，请重新登录') ;
                break;
            case 404:
                message.error('网络请求不存在') ;
                break;
            default:
                message.error('请求失败请联系管理员') ;
                break;
        }
        return err
    }
)
 
// 封装get和post请求
export function get(url, params) {
    return instance.get(url, {params})
}
 
export function post(url, data) {
    return instance.post(url, data)
}
 
export default instance;
import request from "@/utils/request";  //导入封装请求的js文件
import signMd5Utils from '@/utils/encryption/signMd5Utils'

export function getTeAction(url, params, customHeaders) {
  let sign = signMd5Utils.getSign(url, params);
  //将签名和时间戳，添加在请求接口 Header
  // update-begin--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getTimestamp(), ...customHeaders };
  // update-end--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  return request({
    url: url,  //接口路径
    method: "get",  //接口方法
    params: params,  //接口参数
    headers: signHeader
  });
}
export function getAction(url, params) {
  let sign = signMd5Utils.getSign(url, params);
  //将签名和时间戳，添加在请求接口 Header
  // update-begin--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getTimestamp() };
  // update-end--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  return request({
    url: url,  //接口路径
    method: "get",  //接口方法
    params: params,//接口参数
    headers: signHeader,
  });
}
export function postAction(url, params) {
  let sign = signMd5Utils.getSign(url, params);
  //将签名和时间戳，添加在请求接口 Header
  // update-begin--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getTimestamp() };
  // update-end--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  return request({
    url: url,
    method: 'post',
    data: params,
    headers: signHeader
  });
}
export function postTeFileAction(url, params, customHeaders) {
  let sign = signMd5Utils.getSign(url, params);
  //将签名和时间戳，添加在请求接口 Header
  // update-begin--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getTimestamp(), ...customHeaders };
  // update-end--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯

  return request({
    url: url,
    method: 'post',
    params: params,
    headers: signHeader,
    responseType: 'blob'
  });
}
export function postTeAction(url, params, customHeaders) {
  let sign = signMd5Utils.getSign(url, params);
  //将签名和时间戳，添加在请求接口 Header
  // update-begin--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getTimestamp(), ...customHeaders };
  // update-end--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯

  return request({
    url: url,
    method: 'post',
    data: params,
    headers: signHeader,
  });
}
// 通用
export function httpAction(url, params, method) {
  let sign = signMd5Utils.getSign(url, params);
  //将签名和时间戳，添加在请求接口 Header
  // update-begin--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯
  let signHeader = { "X-Sign": sign, "X-TIMESTAMP": signMd5Utils.getTimestamp() };
  // update-end--author:taoyan---date:20220421--for: VUEN-410【签名改造】 X-TIMESTAMP牵扯

  return request({
    url: url,
    method: method,
    params: params,
    headers: signHeader,
  });
}
// export function login(data) {
//   return request({
//     url: "/api/v1/auth/login",
//     method: "post",
//     data,
//     // post请求接口后面拼接参数
//     params: {
//       currentOpenId: 'currentOpenId',
//       currentCompanyOpenId: 'currentCompanyOpenId'
//     }
//   });
// }
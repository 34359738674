<template>
  <div style="padding: 0 100px">
    <div>
      <a-button type="danger" shape="circle" class="fhbtn" size="small" @click="returnOperation()"><a-icon
          type="arrow-left" /></a-button>
    </div>
    <div style="margin-top: 15px">
      <a-row :gutter="16">
        <a-col :span="4">
          <div class="person_letf">
            <div style="text-align: center">
              <!-- <a-avatar style="margin-top: 15px" :size="96" icon="user" /> -->
              <span v-show="this.userInformation.roleType == 1">
                <img src="@/assets/image/供应商.png" alt="" style="width: 100px" />
                <p style="text-align: center; margin-top: 15px">供应商</p>
              </span>
              <span v-show="this.userInformation.roleType == 2">
                <img src="@/assets/image/评审专家.png" alt="" style="width: 100px" />
                <p style="text-align: center; margin-top: 15px">评审专家</p>
              </span>
            </div>
            <!-- <span v-if="editaction == '修改信息'">
            <div style="text-align: center">
              <a-button
                type="primary"
                style="border-radius: 100px; width: 150px; height: 40px; line-height: 40px; margin-top: 20px"
                @click="switchingmethod('修改信息')"
                >修改信息</a-button
              >
            </div>
            <div class="alternatebtn" style="margin-top: 30px; text-align: center" @click="switchingmethod('修改密码')">
              <a style="width: 150px; height: 40px; line-height: 40px;">修改密码</a>
            </div>
          </span>
          <span v-if="editaction == '修改密码'">
            <div style="text-align: center">
              <div class="alternatebtn" style="width: 150px; height: 40px; line-height: 40px; margin: 35px auto 0 auto" @click="switchingmethod('修改信息')">
                <a>修改信息</a></div>
            </div>
            <div style="margin-top: 30px; text-align: center">
                <a-button
                type="primary"
                style="border-radius: 100px; width: 150px; height: 40px; line-height: 40px; margin-top: -15px"
                @click="switchingmethod('修改密码')"
                >修改密码</a-button
              >
            </div>
          </span> -->

            <span style="text-align: center; margin-top: 15px">
              <div class="foundationbtn" :class="editaction == '我参与' ? 'currentbtn' : 'alternatebtn'"
                @click="switchingmethod('我参与')" style="" v-if="this.userInformation.roleType == 1">
                <a :class="editaction == '我参与' ? 'currentabtn' : ''">我参与</a>
              </div>
              <div class="foundationbtn" :class="editaction == '修改信息' ? 'currentbtn' : 'alternatebtn'"
                @click="switchingmethod('修改信息')" style="">
                <a :class="editaction == '修改信息' ? 'currentabtn' : ''">修改信息</a>
              </div>
              <div class="foundationbtn" :class="editaction == '修改密码' ? 'currentbtn' : 'alternatebtn'"
                @click="switchingmethod('修改密码')" style="">
                <a :class="editaction == '修改密码' ? 'currentabtn' : ''">修改密码</a>
              </div>
            </span>
          </div>
        </a-col>
        <a-col :span="20">
          <div class="person_rigth">
            <a-card>
              <p style="font-size: 24px; font-weight: 400; color: #1f2f3d">个人中心</p>
              <a-divider />
              <!-- 我参与 -->
              <span v-show="editaction == '我参与'">
                <p style="font-size: 18px; font-weight: 400; color: #1f2f3d">我参与</p>
                <div></div>
                <div>
                  <announcelistcopy ref="listcopy"></announcelistcopy>
                  <div style="height: 50px;"></div>
                </div>
              </span>
              <!-- 修改信息 -->
              <span v-show="editaction == '修改信息'">
                <p style="font-size: 18px; font-weight: 400; color: #1f2f3d">修改信息</p>
                <div style="width: 90%; min-height: 300px; margin: 0 auto">
                  <a-spin :spinning="txtloading">
                    <a-form-model :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" ref="editformState"
                      :rules="editvalidatorRulesone">
                      <a-form-model-item label="账号">
                        <a-input v-model="formState.username" disabled />
                      </a-form-model-item>
                      <a-form-model-item label="邮箱" prop="mail">
                        <a-input v-model="formState.mail" />
                      </a-form-model-item>
                      <!-- <p>供应商填的（排版时候暂时显示出来）</p> -->
                      <span v-if="formState.roleType == 1">
                        <a-form-model-item label="企业全称" prop="company">
                          <a-input v-model="formState.company" />
                        </a-form-model-item>
                        <a-form-model-item label="营业执照" prop="businessLicense">
                          <div class="clearfix">
                            <a-upload name="multipartFile" accept=".jpg,.png,.jpeg" :multiple="false" :action="uploadImgUrl"
                              list-type="picture-card" :file-list="fileImgList" :beforeUpload="beforeImgUpload"
                              @preview="handleImgPreview('执照')" @change="tehandleImgChange">
                              <div v-if="fileImgList.length < 1">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">上传</div>
                              </div>
                            </a-upload>
                            <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="PreviewhandleCancel">
                              <img alt="example" style="width: 100%" :src="previewImage" />
                            </a-modal>
                            <label style="color: red">请传上尺寸在800x800，大小在1M以内的图片</label>
                          </div>
                        </a-form-model-item>
                        <a-form-model-item label="附件" prop="files">
                          <!-- <a-input v-model="formState.businessLicense" /> -->
                          <div class="clearfix">
                            <a-upload name="multipartFile" :multiple="false" :action="uploadFileUrl"
                              :beforeUpload="beforeUploadFile" :file-list="fileList" @preview="handlePreview('执照')"
                              @change="tehandleChange">
                              <!-- <div v-if="fileList.length < 1">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">上传</div>
                              </div> -->
                              <a-button v-if="fileList.length < 1"> <a-icon type="upload" /> 点击上传 </a-button>
                            </a-upload>
                            <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="PreviewhandleCancel">
                              <img alt="example" style="width: 100%" :src="previewImage" />
                            </a-modal>
                          </div>
                        </a-form-model-item>
                      </span>
                      <!-- <p>专家填的（排版时候暂时显示出来）</p> -->
                      <span v-if="formState.roleType == 2">
                        <a-form-model-item label="所属单位" prop="company">
                          <a-input v-model="formState.company" />
                        </a-form-model-item>
                        <a-form-model-item label="专业领域" prop="specialty">
                          <!-- <a-input v-model="formState.specialty" /> -->
                          <a-select v-model="formState.specialtyList" mode="multiple"
                            :getPopupContainer="(triggerNode) => triggerNode.parentNode" @change="professionalFieldFun">
                            <a-select-option v-for="d in areadata" :key="d.id" :value="d.id">{{
                              d.name
                            }}</a-select-option>
                          </a-select>
                        </a-form-model-item>
                        <a-form-model-item label="相关证书" prop="relevantCertificates">
                          <!-- <a-input v-model="formState.relevantCertificates" /> -->
                          <div class="clearfix">
                            <a-upload name="multipartFile" accept=".jpg,.png,.jpeg" :multiple="true" :action="uploadImgUrl"
                              :beforeUpload="beforeUpload" list-type="picture-card" :file-list="fileList"
                              @preview="handlePreview('证书')" @change="tehandleChangezsbook">
                              <div v-if="fileList.length < 1">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">上传</div>
                              </div>
                            </a-upload>
                            <a-modal :visible="previewVisible" title="预览" :footer="null" @cancel="PreviewhandleCancel">
                              <img alt="example" style="width: 100%" :src="previewImage" />
                            </a-modal>
                          </div>
                        </a-form-model-item>
                      </span>
                      <a-form-model-item label="联系人" prop="contacts">
                        <a-input v-model="formState.contacts" />
                      </a-form-model-item>
                      <a-form-model-item label="手机号" prop="phone">
                        <a-input v-model="formState.phone" />
                      </a-form-model-item>
                      <a-form-model-item label="其他电话">
                        <a-input v-model="formState.otherPhone" />
                      </a-form-model-item>
                    </a-form-model>
                    <div style="text-align: right">
                      <a-button type="primary" @click="submitModifications(formState)">提交</a-button>
                    </div>
                  </a-spin>
                </div>
              </span>
              <!-- 修改密码 -->
              <span v-show="editaction == '修改密码'">
                <p style="font-size: 18px; font-weight: 400; color: #1f2f3d">修改密码</p>
                <div style="width: 90%; min-height: 300px; margin: 0 auto">
                  <a-form-model :model="editformpassword" :label-col="labelCol" :wrapper-col="wrapperCol"
                    ref="editmpasswordtst" :rules="rulespassword">
                    <!-- :rules="editvalidatorRulespassword" -->
                    <a-form-model-item label="旧密码" prop="oldPassword">
                      <a-input-password v-model="editformpassword.oldPassword"></a-input-password>
                    </a-form-model-item>

                    <a-form-model-item label="新密码" prop="newPassword">
                      <a-input-password v-model="editformpassword.newPassword"></a-input-password>
                    </a-form-model-item>

                    <a-form-model-item label="确认新密码" prop="nnewpassword">
                      <a-input-password v-model="editformpassword.nnewpassword"></a-input-password>
                    </a-form-model-item>
                  </a-form-model>
                  <div style="text-align: center">
                    <a-button type="primary" @click="submiteditpassword(editformpassword)">提交</a-button>
                  </div>
                </div>
              </span>
            </a-card>
            <div style="height: 100px;"></div>
          </div>
        </a-col>
      </a-row>

    </div>
    <!-- <div style="height: 100px"></div> -->
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction, httpAction, getTeAction, postTeFileAction, postTeAction } from '@/api/index'
import announcelistcopy from './modules/announceListCopy.vue'
import backEndAddressUrl from '../../utils/backEndAddressUrl'
export default {
  components: {
    announcelistcopy,
  },
  data() {
    return {
      formState: {
        username: '',
        mail: '',
        contacts: '',
        phone: '',
        otherPhone: '',
        businessLicense: '',
        company: '',
        specialty: '',
        relevantCertificates: '',
      },
      labelCol: { span: 9 },
      wrapperCol: { span: 8 },
      userInformation: {},
      uploadImgUrl: '',
      uploadFileUrl: '',
      url: {
        // getip: '/file/getIP', //获取本机ip-get
        edit: '/user/zongshiUser/edit', //修改认证信息-post
        getiduser: '/user/zongshiUser/queryById', //通过id查询用户详情
        editpassword: '/user/zongshiUser/editPassword', //修改密码-post
        deletUrl: "/user/userExpertSpecialty/editInternal",
      },
      fileList: [],
      fileImgList: [],
      previewVisible: false,
      previewImage: '',
      ipdata: '',
      editaction: '我参与',
      editvalidatorRulesone: {
        company: [{ required: true, message: '请输入单位名称！', trigger: 'blur' }],
        mail: [
          { required: true, message: '请输入邮箱！', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, message: '请填写正确的邮箱!' },
        ],
        contacts: [{ required: true, message: '请输入联系人名称！', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号！', trigger: 'blur' },
          { pattern: /^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/, message: '请填写正确的手机号码!' },
        ],
        relevantCertificates: [{ required: true, message: '请上传相关证书！', trigger: 'blur' }],
        specialty: [{ required: true, message: '请选择专业领域！', trigger: 'blur' }],
        businessLicense: [{ required: true, message: '请上传营业执照！', trigger: 'blur' }],
        // xxx: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      editformpassword: {
        oldPassword: '',
        newPassword: '',
        nnewpassword: '',
      },
      rulespassword: {
        oldPassword: [{ required: true, message: '请输入旧密码！', trigger: 'blur' }],
        newPassword: [{ required: true, message: '请输入新密码！', trigger: 'blur' }],
        nnewpassword: [
          { required: true, message: '请再次输入新密码！', trigger: 'blur' },
          { validator: this.validatePasswordConfirmation, trigger: 'blur' },
        ],
      },
      beforepassword: '',
      areadata: [],
      txtloading: false,
      uploadBlocked: false
    }
  },
  created() {
    this.userInformation = {}
    this.resetting()
    const storedData = sessionStorage.getItem('txtuserData')
    if (storedData) {
      console.log('有登录信息')
      this.userInformation = JSON.parse(storedData)
      this.formState = JSON.parse(JSON.stringify(this.userInformation))
      console.log(this.userInformation)
      if (this.userInformation.roleType == 1) {
        this.editaction = '我参与'
        // console.log('1',this.userInformation.roleType,this.editaction)
      } else if (this.userInformation.roleType == 2) {
        this.editaction = '修改信息'
        // console.log('2',this.userInformation.roleType,this.editaction)
      }
    } else {
      console.log('暂无登录信息')
    }
    this.getIp()
    if (this.userInformation.roleType == 1) {
      this.fileImgList.push({
        uid: '-1',
        name: this.userInformation.businessLicense,
        status: 'done',
        url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.userInformation.businessLicense,
      })
      this.fileList.push({
        uid: '-1',
        name: this.userInformation.files,
        status: 'done',
        url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.userInformation.files,
      })

    } else {
      console.log(this.userInformation.relevantCertificates)
      this.fileList.push({
        uid: '-1',
        name: 'image.png',
        status: 'done',
        url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.userInformation.relevantCertificates,
      })
    }
    // console.log('123123',this.fileList)
    if (this.editaction == '我参与') {
      this.$nextTick(() => {
        this.$refs.listcopy.entrymethod(this.userInformation.username, this.userInformation.token)
      })
    } else if (this.editaction == '修改信息') {
      this.initDictConfig()
    }
  },
  methods: {
    professionalFieldFun(value, row) {
      var textName = ''
      console.log(value, row)
      for (let i = 0; i < row.length; i++) {
        const element = row[i].componentOptions.children[0].text;

        if (i == row.length - 1) {
          textName = textName + element
          this.formState.specialty = textName
          console.log(textName)
        } else {
          textName += element + ','
        }
      }
    },
    returnOperation() {
      console.log('返回列表页')
      this.$emit('child-event', '查看列表页')
    },
    // 重置表单信息
    resetting() {
      console.log('重置')
      this.formState = {
        username: '',
        mail: '',
        contacts: '',
        phone: '',
        otherPhone: '',
        businessLicense: '',
        company: '',
        specialty: '',
        relevantCertificates: '',
      }
      this.fileList = []
      this.previewVisible = false
      this.previewImage = ''
    },
    // 获取ip地址
    getIp() {
      this.uploadImgUrl = backEndAddressUrl.backEndUrl + '/file/saveImg'
      this.uploadFileUrl = backEndAddressUrl.backEndUrl + '/file/saveFile'//上传文件
      // getAction(this.url.getip).then((res) => {
      //   // console.log(res)
      //   if (res.code == 200) {
      //     // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
      //     // this.zcformState.relevantCertificates = 'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
      //     this.uploadImgUrl = 'http://' + res.result + '/jeecg-boot/file/save'
      //     this.ipdata = res.result
      //     console.log('获取的ip地址', this.ipdata, this.uploadImgUrl)
      //   } else {
      //     // this.$message.warning('获取IP地址失败')
      //     console.log('获取ip地址失败')
      //   }
      // })
    },
    // 上传图片相关
    handlePreview(file) {
      console.log('点击查看图片', file,)
      this.previewImage = ''
      if (file == '执照') {
        // return console.log(this.fileList)
        // this.previewImage = this.formState.businessLicense
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.formState.files
        let doewurl = "/file/upload?fileName=" + this.formState.files
        const customHeaders = {
          // 在这里设置请求头，例如Authorization等
          Authorization: this.formState.token,
        }
        postTeFileAction(doewurl, "", customHeaders).then((data) => {
          console.log(data)
          this.fileDownloadBtnFun(data)
          // this.fileSize = (data.size / (1024 * 1024)).toFixed(2); // 转换为兆字节数，保留两位小数
          // this.fileBolb = data
          // this.confirmLoading = false
        }).finally(() => {
          //  this.confirmLoading = false;
        })


      } else if (file == '证书') {
        // this.previewImage = this.formState.relevantCertificates
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.formState.relevantCertificates
        this.previewVisible = true
      }

    },
    handleImgPreview(file) {
      // console.log('点击查看图片', file, this.formState.businessLicense)
      this.previewImage = ''
      if (file == '执照') {
        // this.previewImage = this.formState.businessLicense
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.formState.businessLicense
      } else if (file == '证书') {
        // this.previewImage = this.formState.relevantCertificates
        this.previewImage = backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.formState.relevantCertificates
      }
      this.previewVisible = true
    },
    fileDownloadBtnFun(data) {
      console.log("文件下载")
      const dotIndex = this.formState.files.lastIndexOf(".");
      var fileNameNO
      if (dotIndex !== -1) {
        fileNameNO = this.formState.files.slice(dotIndex + 1);
      } else {
      }
      if (!data || data.size === 0) {
        Vue.prototype['$message'].warning('文件下载失败')
        return
      }
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(new Blob([data]), "附件." + fileNameNO)
      } else {
        let url = window.URL.createObjectURL(new Blob([data]))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', "附件." + fileNameNO)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link) //下载完成移除元素
        window.URL.revokeObjectURL(url) //释放掉blob对象
      }
    },
    beforeImgUpload(file) {
      this.uploadBlocked = false
      const isLt1M = file.size / 1024 / 1024 < 1 // 限制文件大小为 1MB
      let image = new Image()
      if (!isLt1M) {
        this.$message.error('图片大小不能超过 1MB！')
        this.fileImgList = []
        this.csqk()
        this.uploadBlocked = true
        return false
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        image.src = e.target.result
        image.onload = () => {
          if (image.width <= 800 && image.height <= 800) {
            this.$message.success('图片尺寸和大小符合要求！')
            this.uploadBlocked = true
          } else {
            this.$message.error('图片尺寸必须在 800x800 以内！')
            this.fileImgList = []
            this.uploadBlocked = true
          }
        }
      }
      reader.readAsDataURL(file)
      this.uploadBlocked = false
      return true // 允许上传
    },
    // 执照
    tehandleImgChange({ fileList }) {
      // console.log(fileList, fileList.length)
      // console.log('1111',this.uploadBlocked,fileList)
      if (this.uploadBlocked == false) {
        this.fileImgList = fileList
      }
      if (fileList.length != 0) {
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            // getAction(this.url.getip).then((res) => {
            //   console.log(res)
            //   if (res.code == 200) {
            // console.log('http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message)
            // this.zcformState.businessLicense =
            //   'http://' + res.result + '/jeecg-boot/file/imgUrl/' + response.message
            // this.zcformState.businessLicense = window._CONFIG['domianURL'] + '/file/imgUrl/' + response.message
            this.formState.businessLicense = response.message
            //   } else {
            //     this.$message.warning('获取IP地址失败')
            //   }
            // })
          } else {
            console.log('上传失败:', response.message)
            this.fileImgList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileImgList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.fileImgList = []
        this.formState.businessLicense = ''
      }
    },
    // 附件
    tehandleChange({ fileList }) {
      // console.log(fileList, fileList.length)
      if (this.uploadBlocked == false) {
        this.fileList = fileList
      }
      // this.fileList = fileList
      if (fileList.length != 0) {
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            this.formState.files = response.message
          } else {
            console.log('上传失败:', response.message)
            this.fileList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.fileList = []
        this.formState.files = ''
      }
    },
    // 证书
    tehandleChangezsbook({ fileList }) {
      // console.log(fileList, fileList.length)
      if (this.uploadBlocked == false) {
        this.fileList = fileList
      }
      // this.fileList = fileList
      if (fileList.length != 0) {
        // 判断是否上传成功
        if (fileList[0].status === 'done') {
          const response = fileList[0].response
          if (response && response.success) {
            console.log('上传成功:', response.message) // 文件访问链接
            this.formState.relevantCertificates = response.message
          } else {
            console.log('上传失败:', response.message)
            this.fileList = []
            this.previewVisible = false
            this.previewImage = ''
          }
        } else if (fileList[0].status === 'error') {
          console.log('上传失败')
          this.fileList = []
          this.previewVisible = false
          this.previewImage = ''
        }
      } else {
        console.log('删除图片')
        this.fileList = []
        this.formState.relevantCertificates = ''
      }
    },
    // 关闭预览
    PreviewhandleCancel() {
      this.previewVisible = false
    },
    // 提交修改认证
    submitModifications(subdata) {
      console.log(subdata)
      // if (subdata.roleType == 1) {
      //   console.log('供应商')
      // } else if(subdata.roleType == 2) {
      //   console.log('专家')
      // }
      this.$refs.editformState.validate((valid) => {
        if (valid) {
          const customHeaders = {
            // 在这里设置请求头，例如Authorization等
            Authorization: this.userInformation.token,
          }
          // httpAction(this.url.deletUrl + "?id=" + subdata.id, '', 'post').then((ress) => {
          //   console.log(ress)
          //   // tSpecialty
          //   for (let i = 0; i < subdata.specialty.length; i++) {
          //     const item = subdata.specialty[i];
          //     var newForm = {
          //       expertSpecialtyId: item,
          //       zongshiUserId: subdata.id
          //     }
          //     httpAction(this.url.edit, newForm, 'post').then((tSpecialtyRes) => {

          //     })

          //   }


          // })
          // return console.log(subdata)
          postTeAction(this.url.edit, subdata, customHeaders).then((res) => {
            console.log(res)
            if (res.code == 200) {
              sessionStorage.removeItem('txtuserData')
              sessionStorage.setItem('txtuserData', JSON.stringify(subdata))
              this.$message.success('修改信息成功')
            } else {
              if (res.message == '该用户所使用的邮箱已存在') {
                this.$message.warning(res.message)
              } else {
                this.$message.warning('修改信息失败')
              }
            }
          })
        } else {
          this.$message.warning('还有未填写或填写有误的信息')
        }
      })
    },
    // 切换方法
    switchingmethod(methodtxt) {
      console.log('切换', methodtxt)
      this.fileList = []
      this.beforepassword = ''
      if (methodtxt != '' && methodtxt != null && methodtxt != undefined) {
        if (methodtxt == '修改信息') {
          this.$refs.editmpasswordtst.resetFields()
          this.initDictConfig()
          const storedData = sessionStorage.getItem('txtuserData')
          this.userInformation = JSON.parse(storedData)
          this.formState = JSON.parse(JSON.stringify(this.userInformation))
          if (this.userInformation.roleType == 1) {
            // debugger;
            // this.fileImgList.push({
            //   uid: '-1',
            //   name: this.userInformation.businessLicense,
            //   status: 'done',
            //   url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.userInformation.businessLicense,
            // })
            this.fileList.push({
              uid: '-1',
              name: this.userInformation.files,
              status: 'done',
              url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.userInformation.files,
            })
          } else {
            this.fileList.push({
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: backEndAddressUrl.backEndUrl + '/file/imgUrl/' + this.userInformation.relevantCertificates,
            })
          }
        } else if (methodtxt == '修改密码') {
          this.$refs.editformState.resetFields()
          // this.reseteditformpassword()
          const customHeaders = {
            // 在这里设置请求头，例如Authorization等
            Authorization: this.userInformation.token,
          }
          getTeAction(this.url.getiduser + '?id=' + this.userInformation.id, {}, customHeaders).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.beforepassword = res.result.password
            } else {
              console.log('查询个人信息失败！')
            }
          })
        } else if (methodtxt == '我参与') {
          this.$nextTick(() => {
            this.$refs.listcopy.entrymethod(this.userInformation.username, this.userInformation.token)
          })
        }
        this.editaction = methodtxt
        // this.$nextTick(() => {
        //   this.$refs.editformpasswordtst.validate() // 手动触发校验初始化
        // })
      } else {
        if (this.userInformation.roleType == 1) {
          this.editaction = '我参与'
          this.$nextTick(() => {
            this.$refs.listcopy.entrymethod(this.userInformation.username, this.userInformation.token)
          })
        } else if (this.userInformation.roleType == 2) {
          this.formState = JSON.parse(JSON.stringify(this.userInformation))
          if (this.userInformation.roleType == 1) {
            this.fileList.push({
              uid: '-1',
              name: this.userInformation.businessLicense,
              status: 'done',
              url: this.userInformation.businessLicense,
            })
          } else {
            this.fileList.push({
              uid: '-1',
              name: 'image.png',
              status: 'done',
              url: this.userInformation.relevantCertificates,
            })
          }
          this.editaction = '修改信息'
        }
        // this.editaction = '我参与'
        // this.$nextTick(() => {
        //   this.$refs.listcopy.entrymethod(this.userInformation.username, this.userInformation.token)
        // })
      }
    },
    // 重置密码表单
    reseteditformpassword() {
      this.editformpassword = {
        oldPassword: '',
        newPassword: '',
        nnewpassword: '',
      }
    },
    // 提交修改密码
    submiteditpassword(editformpassword) {
      console.log('修改密码')
      console.log(editformpassword)
      this.$refs.editmpasswordtst.validate((valid) => {
        if (valid) {
          const customHeaders = {
            // 在这里设置请求头，例如Authorization等
            Authorization: this.userInformation.token,
          }
          this.$set(editformpassword, 'id', this.userInformation.id)
          this.$set(editformpassword, 'username', this.userInformation.username)
          this.$set(editformpassword, 'roleType', this.userInformation.roleType)
          postTeAction(this.url.editpassword, editformpassword, customHeaders).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message.success('修改成功')
              if (this.userInformation.roleType == 1) {
                this.switchingmethod('我参与')
              } else {
                this.switchingmethod('修改信息')
              }
              // this.switchingmethod('我参与')
            } else if (res.code == 500) {
              if (res.message == '旧密码输入不对') {
                this.$message.warning(res.message)
              } else {
              }
            }
          })
        } else {
          console.log('填写信息有误')
        }
      })
    },
    validatePasswordConfirmation(rule, value, callback) {
      if (value === this.editformpassword.newPassword) {
        callback() // 校验通过
      } else {
        callback(new Error('确认密码与密码不一致')) // 校验失败
      }
    },
    // 查询专业领域
    initDictConfig() {
      this.areadata = []
      this.txtloading = true
      getAction('/user/expertSpecialty/list' + '?pageNo=' + 1 + '&pageSize=' + 999).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.areadata = res.result.records
        } else {
          this.areadata = []
        }
        setTimeout(() => {
          this.txtloading = false
        }, 1000)
      })
    },
    beforeUpload(file) {
      this.uploadBlocked = false
      const isLt1M = file.size / 1024 / 1024 < 1 // 限制文件大小为 1MB
      let image = new Image()
      if (!isLt1M) {
        this.$message.error('图片大小不能超过 1MB！')
        this.fileList = []
        this.csqk()
        this.uploadBlocked = true
        return false
      }
      const reader = new FileReader()
      reader.onload = (e) => {
        image.src = e.target.result
        image.onload = () => {
          if (image.width <= 800 && image.height <= 800) {
            this.$message.success('图片尺寸和大小符合要求！')
            this.uploadBlocked = true
          } else {
            this.$message.error('图片尺寸必须在 800x800 以内！')
            this.fileList = []
            this.uploadBlocked = true
          }
        }
      }
      reader.readAsDataURL(file)
      this.uploadBlocked = false
      return true // 允许上传
    },
    beforeUploadFile(file) {
      this.uploadBlocked = false
      const isLt1M = file.size / 1024 / 1024 < 10 // 限制文件大小为 10MB
      let image = new Image()
      if (!isLt1M) {
        this.$message.error('文件大小不能超过 10MB！')
        this.fileList = []
        // this.csqk()
        this.uploadBlocked = true
        return false
      }
      const reader = new FileReader()
      // reader.onload = (e) => {
      //   image.src = e.target.result
      //   image.onload = () => {
      //     if (image.width <= 800 && image.height <= 800) {
      //       this.$message.success('图片尺寸和大小符合要求！')
      //       this.uploadBlocked = true
      //     } else {
      //       this.$message.error('图片尺寸必须在 800x800 以内！')
      //       this.fileList = []
      //       this.uploadBlocked = true
      //     }
      //   }
      // }
      reader.readAsDataURL(file)
      this.uploadBlocked = false
      return true // 允许上传
    },
  },
}
</script>

<style>
.fhbtn {
  padding: 0 15px !important;
  margin-left: -45px;
  margin-top: 15px;
  height: 50px !important;
  width: 50px;
  line-height: 50px !important;
  border-radius: 50% !important;
}

.person_letf {
  width: 100%;
  min-height: 500px;
  background-color: #f0f2f5;
}

.person_rigth {
  width: 100%;
  height: 100px;
  /* background-color: red; */
}

.alternatebtn {
  width: 140px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  transition: opacity 1s linear;
}

.alternatebtn:hover {
  width: 140px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  background-color: #eee;
  border-radius: 100px;
  margin-bottom: 15px;
  transition: opacity 1s linear;
}

.currentbtn {
  background-color: rgb(24, 144, 255);
  transition: opacity 1s linear;
}

.foundationbtn {
  width: 140px;
  margin: 0 auto;
  height: 40px;
  line-height: 40px;
  margin-bottom: 15px;
  cursor: pointer;
  border-radius: 100px;
}

.currentabtn {
  color: white !important;
}
</style>
<template>
  <div>
    <a-tabs v-model:activeKey="activeKey" @change="handleTabChange">
      <a-tab-pane key="4" tab="预发公告" force-render
        ><a-table
          ref="table"
          size="middle"
          :scroll="{ x: 1080 }"
          bordered
          rowKey="id"
          :columns="columnsOne"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          class="j-table-force-nowrap"
          @change="handleTableChange"
        >
          <span slot="action" slot-scope="text, record">
            <!-- <a style="color: #333;" @click="participationDetails('参与详情', record)">详情</a> -->
            <a-button @click="participationDetails('参与详情', record)">详情</a-button>
            <!-- <a @click="seeDetails('详情', record)">详情</a> -->
            <!-- <a>详情</a> -->
            <!-- <a-divider type="vertical" /> -->
            <!-- <a @click="ApplyMoadlFun(record)">报名</a> -->
            <!-- <a>报名</a> -->
            <!-- <a>暂定</a> -->
          </span>
        </a-table></a-tab-pane
      >
      <a-tab-pane key="1" tab="招标公告" force-render
        ><a-table
          ref="table"
          size="middle"
          :scroll="{ x: 1657 }"
          bordered
          rowKey="id"
          :columns="columns"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          class="j-table-force-nowrap"
          @change="handleTableChange"
        >
        <span slot="projectStatusSlot" slot-scope="text, record">
                      <a-tag v-if="record.projectStatus == 3" color="green">{{ text }}</a-tag>
                      <a-tag v-else-if="record.projectStatus == 4" color="red">{{ text }}</a-tag>
                      <a-tag v-else-if="record.projectStatus == 5" color="purple">{{ text }}</a-tag>
                      <span v-else>{{ text }}</span>
                    </span>
          <span slot="supplementSlot" slot-scope="text, record">
            <span v-if="text == 1">
              <!-- <a @click="participationDetails('参与补充详情', record)">有</a> -->
              <a-button type="danger" shape="circle" @click="participationDetails('参与补充详情', record)"> 有 </a-button>
            </span>
            <span v-else>无</span>
          </span>
          <span slot="action" slot-scope="text, record">
            <!-- <a @click="seeDetails('详情', record)">详情</a> -->
            <!-- <a>详情</a> -->
            <!-- <a-divider type="vertical" /> -->
            <!-- <a @click="ApplyMoadlFun(record)">报名</a> -->
            <a-button @click="participationDetails('参与详情', record)">详情</a-button>
            <!-- <a style="color: #333;" @click="participationDetails('参与详情', record)">详情</a> -->
            <span
              v-if="
                record.type == 1 &&
                record.registrationStatus == 2 &&
                record.projectStatus != 4 &&
                record.projectStatus != 5 &&
                record.projectStatus != 7 &&
                record.projectStatus != 9 &&
                userInformation != null &&
                userInformation.roleType == 1 &&
                userInformation.roleType != null &&
                userInformation.roleType != undefined
              "
            >
              <a-divider type="vertical" />
              <!-- <a style="color: red;" @click="payFeesFun(record)">缴费</a> -->
              <a-button type="danger" @click="payFeesFun(record)"> 缴费 </a-button>
            </span>
            <span
              v-if="
                record.type == 1 &&
                (record.registrationStatus == 3 || record.registrationStatus == 4) &&
                record.projectStatus != 5 &&
                record.projectStatus != 7 &&
                record.projectStatus != 9 &&
                userInformation != null &&
                userInformation.roleType == 1 &&
                userInformation.roleType != null &&
                userInformation.roleType != undefined
              "
            >
              <a-divider type="vertical" />
              <!-- <a style="color: green;" @click="downloadTenderFun(record)">下载标书</a> -->
              <a-button
                style="color: white !important; background-color: #87d068 !important; border: #87d068 !important"
                @click="downloadTenderFun(record)"
              >
                下载标书
              </a-button>
            </span>
            <span
              v-if="
                record.type == 1 &&
                record.registrationStatus == 3 &&
                (record.projectStatus == 5 || record.projectStatus == 7 || record.projectStatus == 9) &&
                userInformation != null &&
                userInformation.roleType == 1 &&
                userInformation.roleType != null &&
                userInformation.roleType != undefined
              "
              ><a-divider type="vertical" />
              <!-- <a style="color: red" @click="reimbursement(record.id)">退还保证金</a> -->
              <a-button type="danger" @click="reimbursement(record.id)"> 退还保证金 </a-button>
              </span
            >
            <!-- <a>暂定</a> -->
          </span>
        </a-table></a-tab-pane
      >
      <a-tab-pane key="2" tab="中标公告"
        ><a-table
          ref="table"
          size="middle"
          :scroll="{ x: 1080 }"
          bordered
          rowKey="id"
          :columns="columnsTwo"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          class="j-table-force-nowrap"
          @change="handleTableChange"
        >
          <span slot="supplementSlot" slot-scope="text, record">
            <span v-if="text == 1"><a @click="participationDetails('参与补充详情', record)">有</a></span>
            <span v-else>无</span>
          </span>
          <span slot="action" slot-scope="text, record">
            <!-- <a style="color: #333" @click="participationDetails('参与详情', record)">详情</a> -->
            <a-button @click="participationDetails('参与详情', record)">详情</a-button>
            <!-- <a @click="seeDetails('详情', record)">详情</a> -->
            <!-- <a>详情</a> -->
            <!-- <a-divider type="vertical" /> -->
            <!-- <a @click="ApplyMoadlFun(record)">报名</a> -->
            <!-- <a>报名</a> -->
            <!-- <a>暂定</a> -->
          </span>
        </a-table></a-tab-pane
      >
      <a-tab-pane key="3" tab="补充公告"
        ><a-table
          ref="table"
          size="middle"
          :scroll="{ x: 1080 }"
          bordered
          rowKey="id"
          :columns="columnsOne"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          class="j-table-force-nowrap"
          @change="handleTableChange"
        >
          <span slot="action" slot-scope="text, record">
            <!-- <a style="color: #333" @click="participationDetails('参与详情', record)">详情</a> -->
            <a-button @click="participationDetails('参与详情', record)">详情</a-button>
          </span>
        </a-table></a-tab-pane
      >
      <a-tab-pane key="5" tab="流标公告"
        ><a-table
          ref="table"
          size="middle"
          :scroll="{ x: 1080 }"
          bordered
          rowKey="id"
          :columns="columnsTwo"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          class="j-table-force-nowrap"
          @change="handleTableChange"
        >
          <span slot="supplementSlot" slot-scope="text, record">
            <span v-if="text == 1"><a @click="participationDetails('参与补充详情', record)">有</a></span>
            <span v-else>无</span>
          </span>
          <span slot="action" slot-scope="text, record">
            <!-- <a style="color: #333" @click="participationDetails('参与详情', record)">详情</a> -->
            <a-button @click="participationDetails('参与详情', record)">详情</a-button>
          </span>
        </a-table></a-tab-pane
      >
      <a-tab-pane key="6" tab="其他公告">
        <a-table
          ref="table"
          size="middle"
          :scroll="{ x: 1080 }"
          bordered
          rowKey="id"
          :columns="columnsOne"
          :dataSource="dataSource"
          :pagination="ipagination"
          :loading="loading"
          class="j-table-force-nowrap"
          @change="handleTableChange"
        >
          <span slot="action" slot-scope="text, record">
            <!-- <a style="color: #333" @click="participationDetails('参与详情', record)">详情</a> -->
            <a-button @click="participationDetails('参与详情', record)">详情</a-button>
            <!-- <a @click="seeDetails('详情', record)">详情</a> -->
            <!-- <a>详情</a> -->
            <!-- <a-divider type="vertical" /> -->
            <!-- <a @click="ApplyMoadlFun(record)">报名</a> -->
            <!-- <span
              v-if="
                record.type == 1 &&
                record.registrationStatus == 2 &&
                record.projectStatus != 4 &&
                record.projectStatus != 5 &&
                userInformation != null &&
                userInformation.roleType == 1 &&
                userInformation.roleType != null &&
                userInformation.roleType != undefined
              "
              ><a @click="payFeesFun(record)">缴费</a></span
            >
            <span
              v-if="
                record.type == 1 &&
                (record.registrationStatus == 3 || record.registrationStatus == 4) &&
                record.projectStatus != 5 &&
                userInformation != null &&
                userInformation.roleType == 1 &&
                userInformation.roleType != null &&
                userInformation.roleType != undefined
              "
              ><a @click="downloadTenderFun(record)">下载标书</a>
            </span>
            <span
              v-if="
                record.type == 1 &&
                record.registrationStatus == 3 &&
                record.projectStatus != 4 &&
                record.projectStatus != 5 &&
                userInformation != null &&
                userInformation.roleType == 1 &&
                userInformation.roleType != null &&
                userInformation.roleType != undefined
              "
              ><a-divider type="vertical"></a-divider><a @click="reimbursement(record.id)">退还保证金</a></span
            >
            <a>暂定</a> -->
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <!-- 缴费 -->
    <Announce-Deposit-Payment ref="DepositPayment" @child-event="refreshList"></Announce-Deposit-Payment>
    <!-- 下载标书 -->
    <Announce-File-Download ref="announceFileDownload"></Announce-File-Download>
    <!-- 申请退还保证金 -->
    <Refund-Application-Form ref="drawback"></Refund-Application-Form>
    <!-- 详情弹窗 -->
    <a-modal v-model="detailsVisible" title="详情" @ok="handleOk" width="80%">
      <p style="text-align: center; font-size: 28px; font-weight: 400; color: #1f2f3d">{{ contentdata.title }}</p>
      <p style="text-align: center; font-size: 14px; color: #5e6d82; line-height: 1.5em">
        <span v-if="contentdata.type == 1">报名时间：{{ contentdata.registrationValidityTime }}</span>
        <span v-if="contentdata.type == 1" style="margin: 0 15px">开标时间：{{ contentdata.bidOpeningTime }}</span>
        <span>发布日期：{{ contentdata.releaseTime }}</span>
      </p>
      <div style="padding: 0 15px">
        <a-divider />
      </div>
      <div class="tstimg" style="max-width: 900px; margin: 0 auto; font-size: 14px; color: #5e6d82; line-height: 1.5em">
        <div class="tsbj" v-html="contentdata.illustrate"></div>
      </div>
      <div style="height: 100px"></div>
      <template #footer>
        <a-button key="back" @click="handleCancel">关 闭</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { getAction, deleteAction, putAction, postAction, httpAction, getTeAction } from '@/api/index'

import AnnounceDepositPayment from '../AnnounceDepositPayment.vue'
import AnnounceFileDownload from '../AnnounceFileDownload.vue'
import RefundApplicationForm from '../RefundApplicationForm.vue'
export default {
  components: {
    AnnounceDepositPayment,
    AnnounceFileDownload,
    RefundApplicationForm,
  },
  data() {
    return {
      activeKey: '1',
      columns: [
        {
          title: '招标公告名称',
          dataIndex: 'title',
          // align: 'center',
          width: 400,
          ellipsis: true,
          customRender: (text) => {
            return <span style="font-weight: bold">{text}</span>
          },
        },
        {
          title: '状态',
          dataIndex: 'projectStatus_dictText',
          width: 100,
          align: 'center',
          scopedSlots: { customRender: 'projectStatusSlot' },
        },
        {
          title: '采购类型',
          dataIndex: 'purchaseInformation_dictText',
          width: 100,
          align: 'center',
        },
        {
          title: '招标类型',
          dataIndex: 'type_dictText',
          width: 100,
          align: 'center',
        },
        {
          title: '补充公告',
          dataIndex: 'supplement',
          width: 120,
          align: 'center',
          scopedSlots: { customRender: 'supplementSlot' },
        },
        {
          title: '报名时间',
          dataIndex: 'registrationValidityTime',
          width: 210,
          align: 'center',
        },
        {
          title: '开标时间',
          dataIndex: 'bidOpeningTime',
          width: 190,
          align: 'center',
        },
        {
          title: '投标截止时间',
          dataIndex: 'bidDeadline',
          width: 190,
          align: 'center',
          customRender: (text, record) => {
            return {
              children: text,
              attrs: {
                title: `缴费截止时间: ${text}`,
              },
            }
          },
        },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 190,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 207,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnsOne: [
        {
          title: '招标公告名称',
          dataIndex: 'title',
          // align: 'center',
          width: 450,
          ellipsis: true,
          customRender: (text) => {
            return <span style="font-weight: bold">{text}</span>
          },
        },
        {
          title: '采购类型',
          dataIndex: 'purchaseInformation_dictText',
          // width: 180,
          align: 'center',
        },
        {
          title: '招标类型',
          dataIndex: 'type_dictText',
          align: 'center',
        },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 190,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 147,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      columnsTwo: [
        {
          title: '招标公告名称',
          dataIndex: 'title',
          // align: 'center',
          width: 500,
          ellipsis: true,
          customRender: (text) => {
            return <span style="font-weight: bold">{text}</span>
          },
        },
        {
          title: '采购类型',
          dataIndex: 'purchaseInformation_dictText',
          width: 180,
          align: 'center',
        },
        {
          title: '招标类型',
          dataIndex: 'type_dictText',
          width: 180,
          align: 'center',
        },
        {
          title: '补充公告',
          dataIndex: 'supplement',
          width: 180,
          align: 'center',
          scopedSlots: { customRender: 'supplementSlot' },
        },
        {
          title: '发布时间',
          dataIndex: 'releaseTime',
          width: 190,
          align: 'center',
        },
        {
          title: '操作',
          align: 'center',
          width: 147,
          fixed: 'right',
          scopedSlots: { customRender: 'action' },
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        pageSize: 30,
        pageSizeOptions: ['10', '20', '30'],
        showTotal: (total, range) => {
          return range[0] + '-' + range[1] + ' 共' + total + '条'
        },
        // showQuickJumper: true,
        // showSizeChanger: true,
        total: 0,
      },
      loading: false,
      username: '',
      usertoken: '',
      userInformation: {},
      url: {
        listmy: '/project/bidAnnouncement/listByBid',
        queryById: '/project/bidAnnouncement/queryById',
        supplement: '/project/bidAnnouncement/supplement',
      },
      detailsVisible: false,
      contentdata: {},
    }
  },
  methods: {
    entrymethod(txtuser, txttoken) {
      console.log(txtuser, txttoken)
      this.loading = true
      this.thelogininformation()
      this.ipagination.pageSize = 30
      this.ipagination.current = 1
      this.username = txtuser
      this.usertoken = txttoken
      if (txtuser != '' && txtuser != null && txtuser != undefined) {
        this.obtainlist()
      } else {
        this.$message.warning('未获取到用户')
        this.loading = false
      }
    },
    // 获取列表
    obtainlist() {
      this.loading = true
      let ggtype = ''
      if (this.activeKey == '0') {
      } else {
        ggtype = this.activeKey
      }
      console.log('获取token', this.usertoken)
      const customHeaders = {
        // 在这里设置请求头，例如Authorization等
        Authorization: this.usertoken,
      }
      getTeAction(
        this.url.listmy +
          '?type=' +
          ggtype +
          '&announcementState=' +
          1 +
          '&my=' +
          this.username +
          '&pageNo=' +
          this.ipagination.current +
          '&pageSize=' +
          this.ipagination.pageSize,
        {},
        customHeaders
      ).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.ipagination.total = res.result.total
          this.dataSource = res.result.records
        } else {
          this.$message.warning('查询出错')
        }
        this.loading = false
      })
    },
    // 点击分页触发
    handleTableChange(pagination, filters, sorte) {
      //分页、排序、筛选变化时触发
      //TODO 筛选
      console.log('点击分页变化', pagination)
      this.ipagination.pageSize = pagination.pageSize
      this.ipagination.current = pagination.current
      this.obtainlist()
    },
    // 切换标签
    handleTabChange() {
      console.log('点击cars', this.activeKey)
      this.loading = true
      this.dataSource = []
      if (this.username != '' && this.username != null && this.username != undefined) {
        this.ipagination.pageSize = 30
        this.ipagination.current = 1
        setTimeout(() => {
          // this.resetSearch()
          this.obtainlist()
        }, 200) // 1000 毫秒延迟
      } else {
        this.$message.warning('未获取到当前登录用户,请刷新页面')
        this.loading = false
      }
    },
    // 获取当前登录用户信息
    thelogininformation() {
      const storedData = sessionStorage.getItem('txtuserData')
      if (storedData) {
        console.log('有登录信息')
        this.userInformation = JSON.parse(storedData)
        this.formState = JSON.parse(JSON.stringify(this.userInformation))
        console.log(this.userInformation)
      } else {
        console.log('暂无登录信息')
        this.userInformation = {}
      }
    },
    // 缴费
    payFeesFun(row) {
      // console.log(row)
      console.log(row)
      console.log('缴费截至时间', row.bidDeadline)
      const startDate = new Date(row.bidDeadline)
      // startDate.setHours(23, 59, 59, 999)
      console.log('转换的截至时间', startDate)
      const currentTime = new Date()
      console.log('获取当前时间', currentTime)
      // currentTime.setHours(0, 0, 0, 0)
      // startDate.setHours(0, 0, 0, 0)
      console.log('判断', currentTime > startDate)
      if (currentTime > startDate == true) {
        console.log('当前时间大于截至时间')
        return this.$message.warning('缴费已截止')
      } else {
        console.log('当前时间小于或等于截至时间')
        this.$refs.DepositPayment.edit(row, this.userInformation)
      }
      // this.$refs.DepositPayment.edit(row, this.userInformation)
    },
    // 下载标书
    downloadTenderFun(row) {
      this.$refs.announceFileDownload.edit(row, this.userInformation)
    },
    reimbursement(Anid) {
      this.$refs.drawback.openARefund(Anid, this.userInformation.token)
    },
    // 点击详情
    participationDetails(txtaction, row) {
      this.contentdata = {}
      console.log(txtaction, row)
      if (txtaction == '参与详情') {
        getAction(this.url.queryById + '?id=' + row.id).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.contentdata = res.result
          } else {
          }
        })
      } else {
        let supplementData = {
          announcementState: '1',
          tenderProjectId: row.tenderProjectId,
          type: '3',
        }
        postAction(this.url.supplement, supplementData).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.contentdata = res.result[0]
          } else {
          }
        })
      }

      this.detailsVisible = true
    },
    handleOk() {
      this.detailsVisible = false
    },
    handleCancel() {
      this.detailsVisible = false
    },
    refreshList() {
      console.log('刷新')
      this.obtainlist()
    },
  },
}
</script>

<style>
.tstimg img {
  max-width: 100% !important; /* 图片最大宽度为父容器的宽度 */
}
</style>
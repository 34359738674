<template>
    <div>
        <!-- 支付保证金 WX支付宝等 -->
        <a-modal :title="title" :afterClose="depositclose" :footer="null" :width="800" :visible="visible"
            :confirmLoading="confirmLoading" @ok="handleOk" @cancel="handleCancel" cancelText="关闭" :keyboard="false"
            :maskClosable="false">
            <a-spin :spinning="confirmLoading" style="margin-left: 20px;">
                <a-row>
                    <a-col :span="24">
                        <p style="font-size: 18px;">
                            <span style="color: #333;">项目名称：</span> <span style="color: #666666;">{{
                                model.tenderProjectId_dictText
                            }}</span>
                        </p>
                    </a-col>
                    <a-col :span="12">
                        <p style="font-size: 18px; ">
                            <span style="color: #333;">报名时间：</span> <span style="color: #666666;">{{
                                model.registrationValidityTime
                            }}</span>
                        </p>
                    </a-col>
                    <a-col :span="12">
                        <p style="font-size: 18px;">
                            <span style="color: #333;">缴费截止：</span> <span style="color: #666666;">{{ model.bidDeadline
                            }}</span>
                        </p>
                    </a-col>
                    <a-col :span="24">
                        <p style="font-size: 18px;">
                            <span style="color: #333;">投标公司：</span> <span style="color: #666666;">{{ modelUserInfo.company
                            }}</span>
                        </p>
                    </a-col>
                    <a-col :span="12">
                        <p style="font-size: 18px;">
                            <span style="color: #333;">联系人员：</span> <span style="color: #666666;">{{ modelUserInfo.contacts
                            }}</span>
                        </p>
                    </a-col>
                    <a-col :span="12">
                        <p style="font-size: 18px;">
                            <span style="color: #333;">联系电话：</span> <span style="color: #666666;">{{ modelUserInfo.phone
                            }}</span>
                        </p>
                    </a-col>
                    <a-col :span="24">
                        <p style="text-align: right;border-top: 1px solid #302f2f;margin-top: 30px;padding-top: 10px;">
                            <span>应付金额：
                                <span style="color: red;font-size: 38px;font-weight: bold;"> {{ model.bond }} </span>
                                <span> 元</span>
                            </span>
                        </p>
                    </a-col>
                    <p style="text-align: center;margin-top: 50px;"><a-button type="primary"
                            style="width: 150px;height: 50px;font-size: 22px;" size="large"
                            @click="placeOrderFun">去支付</a-button>
                    </p>
                </a-row>
                <!--               选择支付方式微信支付宝  
                <div class="QR_codeImg">

                    <div>
                        <div>
                            <label>选择支付方式：</label>
                            <a-radio-group v-model="value" @change="onChange">
                                <a-radio :value="i" v-for=" (item, i) in setArr" :key="i">
                                    {{ item.name }}
                                </a-radio>
                            </a-radio-group>
                        </div>

                        <div style="text-align: center;">
                            <img :src="item" alt="/" v-for="(item, i) in QrListArr" :key="i">
                        </div>

                    </div>
                </div> -->

            </a-spin>
        </a-modal>
        <a-modal v-model="visibleOSDD" width="600" :centered="true" title="温馨提醒" :footer="null" :closable="false"
            :keyboard="false" :maskClosable="false">
            <div
                style="width: 500px; height: 300px;display: flex; justify-content: center;   align-items: center; font-size: 24px;  ">
                正在查询交易订单...请勿关闭该页面
                <!-- <a-spin class="custom-spin" tip="正在查询交易订单...请勿关闭该页面" :spinning="visibleOSDD">
                    <div class="spin-content" style="width: 500px; height: 500px;">
                    </div>
                </a-spin> -->
            </div>
        </a-modal>
        <Announce-File-Download ref="announceFileDownload"></Announce-File-Download>
    </div>
</template>
  
<script>
import { httpAction, getAction, postTeAction, getTeAction } from '@/api/index'
import AnnounceFileDownload from './AnnounceFileDownload.vue'
export default {
    name: "AnnounceDepositPayment",
    components: {
        AnnounceFileDownload,
    },
    data() {
        return {
            title: "支付保证金",
            visible: false,
            model: {},
            modelUserInfo: {},
            QrListArr: [],
            needInvoice: "check",
            NoNeedInvoice: "",
            labelCol: {
                xs: { span: 24 },
                sm: { span: 3 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
            confirmLoading: false,
            form: this.$form.createForm(this),
            validatorRules: {
                company: [
                    { required: true, message: '请输入公司名称!' },
                    { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' }
                ],
                email: [
                    { required: true, type: 'email', message: '邮箱格式不正确！', trigger: 'blur' }
                ],
                // assertImgs: [
                //   { required: true, message: '请上传营业执照！' }
                // ],
                tel: [
                    { required: true, validator: this.validateMobile, trigger: 'blur' }
                ],
                contacts: [
                    { required: true, message: '请输入联系人!' },
                    { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
                ],
            },
            url: {
                add: "/test/jeecgDemo/add",
                edit: "/test/jeecgDemo/edit",
                tenderParticulars: "/project/bidProject/editStatus",
                tenderParticularsList: '/project/bidProject/editOrder'
            },
            value: 1,
            radioStyle: {
                display: 'block',
                height: '30px',
                lineHeight: '30px',
            },
            applyArr: {
                ALI_APP: '支付宝APP',
                ALI_BAR: '支付宝条码',
                ALI_JSAPI: '支付宝生活号',
                ALI_LITE: '支付宝小程序',
                ALI_PC: '支付宝PC网站',
                ALI_QR: '支付宝二维码',
                ALI_WAP: '支付宝WAP',
                PP_PC: 'PayPal支付',
                UP_APP: '银联App支付',
                UP_B2B: '银联企业网银支付',
                UP_BAR: '银联二维码(被扫)',
                UP_JSAPI: '银联Js支付',
                UP_PC: '银联网关支付',
                UP_QR: '银联二维码(主扫)',
                UP_WAP: '银联手机网站支付',
                WX_APP: '微信APP',
                WX_BAR: '微信条码',
                WX_H5: '微信H5',
                WX_JSAPI: '微信公众号',
                WX_LITE: '微信小程序',
                WX_NATIVE: '微信扫码',
            },
            setApplyArr: [],
            setArr: [],
            // 提交订单返回二维码
            codeQerForm: {
                amount: "",
                appId: "",
                divisionMode: 0,
                mchOrderNo: "",
                orderTitle: "接口调试",
                payDataType: "codeImgUrl",
                wayCode: ""
            },
            // 提交订单返回参数
            orderParameter: {

            },
            // 关闭弹窗时结束递归
            recursionEndsClosed: false,
            unionQueryForm: {
                orderId: "",
                txnTime: "",
            },
            modelWring: null,
            visibleOSDD: false,
        }
    },
    created() {
        // let asdads = {
        //     WX_NATIVE: '64e4101a0c353fb16975068e',
        // }
        // this.getSetArr(asdads)
        // let paymentURl = "/pay/pay/getAppid"
        // httpAction(paymentURl, '', 'get').then((res) => {
        //     console.log("获取当前商户appid", res)
        //     this.getSetArr(res.result)
        // }).finally(() => {
        // })
    },
    methods: {
        // 银联模块
        placeOrderFun() {
            var that = this
            const closingBidsDate = new Date(that.model.bidDeadline);
            const secondDate = new Date(that.formattedTime());
            if (secondDate > closingBidsDate) {
                return that.$message.warning('缴费不在有效时间内');
            }
            // console.log(closingBidsDate, secondDate)
            let paymentURl = "/union/pay?txnAmt=" + Math.round(Number(this.model.bond) * 100) + "&riskRateInfo=" + this.model.tenderProjectId_dictText
            this.$warning({
                title: '温馨提示',
                content: h => <div style="color:red;">本订单有效时间为15分钟，请在有效时间内支付！支付期间请勿关闭本页面！</div>,
                okText: "我知道了",
                onOk() {
                    httpAction(paymentURl, '', 'get').then((res) => {
                        const div = document.createElement('div')
                        div.innerHTML = res //后台返回接收到的html数据
                        document.body.appendChild(div);
                        // 获取银联支付订单号和时间
                        var form = document.forms[document.forms.length - 1];
                        var orderId = form.elements['orderId'].value;
                        var txnTime = form.elements['txnTime'].value;
                        console.log(document.forms[document.forms.length - 1], orderId, txnTime)
                        that.unionQueryForm.orderId = orderId
                        that.unionQueryForm.txnTime = txnTime
                        form.setAttribute('target', '_blank'); // 新开窗口跳转
                        form.submit();

                        const customTenderHeaders = {
                            // 在这里设置请求头，例如Authorization等
                            Authorization: that.modelUserInfo.token,
                        }
                        var newTenderParticularsInfo = {
                            bidder: that.modelUserInfo.username,
                            tenderProjectId: that.model.tenderProjectId,
                            orderId: that.unionQueryForm.orderId
                        }

                        postTeAction(that.url.tenderParticularsList, newTenderParticularsInfo, customTenderHeaders).then((tenderParticularsListRes) => {
                            console.log("查询投标详情", tenderParticularsListRes)
                        }).finally(() => {
                        })
                        that.visibleOSDD = true
                        // that.modelWring = that.$warning({
                        //     title: '温馨提示',
                        //     content: '正在查询交易订单...请勿关闭该页面',
                        //     width: "500px",
                        //     footer: null, // 这里设置footer为null
                        // });
                        // 递归查询订单状态
                        that.recursionEndsClosed = false
                        that.placeOrderRecursionFunction()
                        that.confirmLoading = true
                        // console.log(JSON.stringify(document.forms[document.forms.length-1].submit()) )
                    }).finally(() => {
                    })
                },
                onCancel() {
                    console.log('Cancel');
                    that.$message.warning('已取消支付');
                },
                class: 'test',
            });
        },
        async placeOrderRecursionFunction() {
            // 添加定时器延迟3秒
            await this.delay(3000);
            const response = await this.callBackendPlaceOrderAPI();
            const responseData = response.result; // 假设后端接口返回的数据在 response.data 中
            console.log("查询支付订单", response,this.recursionEndsClosed)
            if (this.recursionEndsClosed == true) {
                return
            }
            // 判断是否满足终止条件，例如 responseData.respCode =='00' 等于 '00'
            if (responseData.respCode == '00' && responseData.origRespCode == '00') {
                this.confirmLoading = false
                console.log("State is 2, stopping recursion.");
                const customHeaders = {
                    // 在这里设置请求头，例如Authorization等
                    Authorization: this.modelUserInfo.token,
                }
                // 查询该公告的投标人信息
                let bidderiInformation = {
                    tenderProjectId: this.model.tenderProjectId,
                    bidder: this.modelUserInfo.username,
                    orderId: this.unionQueryForm.orderId
                }
                let bidderiInformationUrl = "/project/bidProject/editStatus"
                postTeAction(bidderiInformationUrl, bidderiInformation, customHeaders).then((res) => {
                    console.log("修改投标人信息", res)
                }).finally(() => {
                })
                // this.modelWring.destroy()
                this.visibleOSDD = false
                this.$emit('child-event');
                this.countDown()
                
                this.visible = false
                setTimeout(() => {
                    this.$refs.announceFileDownload.edit(this.model, this.modelUserInfo)
                }, 5 * 1000);
                return;
            }
            // if (responseData.data.records[0].state === 3) {
            //     console.log("State is 3, stopping recursion.");
            //     return;
            // }

            console.log("Calling API again...");
            await this.placeOrderRecursionFunction();
        },
        async callBackendPlaceOrderAPI() {
            // 调用后端接口，并返回 Promise 对象
            // 这里使用示例，你需要替换为实际的后端接口调用逻辑
            let trackOrderUrl = "/union/query?orderId=" + this.unionQueryForm.orderId + "&txnTime=" + this.unionQueryForm.txnTime;
            return await httpAction(trackOrderUrl, '', 'get');
        },


        // 银联模块到此
        // 获取当前时间
        formattedTime() {
            let currentTime = new Date()
            const year = currentTime.getFullYear();
            const month = currentTime.getMonth() + 1; // 月份是从0开始的，所以需要加1  
            const day = currentTime.getDate();
            const hour = currentTime.getHours();
            const minute = currentTime.getMinutes();
            const second = currentTime.getSeconds();
            return `${year}-${month}-${day}`;
        },

        getSetArr(row) {
            const uniqueKeys = new Set();
            for (const key in this.applyArr) {
                if (row.hasOwnProperty(key)) {
                    let arrListitem = {
                        name: this.applyArr[key],
                        vuale: row[key],
                        applyId: key
                    }
                    console.log(arrListitem)
                    uniqueKeys.add(arrListitem);

                }
            }
            this.setArr = Array.from(uniqueKeys);
            console.log(this.setArr)
        },
        validateMobile(rule, value, callback) {
            if (new RegExp(/^1([38][0-9]|4[579]|5[0-3,5-9]|6[6]|7[0135678]|9[89])\d{8}$/).test(value)) {
                callback();
            } else {
                callback("您的手机号码格式不正确!");
            }
        },
        add() {
            this.edit({});
        },
        edit(record, userInfo) {
            console.log("报名传参", record, userInfo)
            this.model = Object.assign({}, record);
            this.modelUserInfo = Object.assign({}, userInfo)
            this.visible = true;
        },
        close() {
            // this.$refs.form.resetFields();
            this.$emit('close');
            this.visible = false;
        },
        // 关闭的回调
        depositclose() {
            this.value = 1
            this.QrListArr = []
            this.recursionEndsClosed = true
        },
        btnFun(lisf) {
            if (lisf == false) {
                this.needInvoice = ""
                this.NoNeedInvoice = "check"
            } else {
                this.needInvoice = "check"
                this.NoNeedInvoice = ""
            }
        },
        handleOk() {
            const that = this;
            this.visible = false;
            this.$refs.announceFileDownload.edit(this.model, this.modelUserInfo)
            // 触发表单验证
            // this.$refs.form.validate(valid => {
            //     if (valid) {
            //   that.confirmLoading = true;
            //   let httpurl = '';
            //   let method = '';
            //   if(!this.model.id){
            //     httpurl+=this.url.add;
            //     method = 'post';
            //   }else{
            //     httpurl+=this.url.edit;
            //      method = 'put';
            //   }
            // console.log("校验")
            //   httpAction(httpurl,this.model,method).then((res)=>{
            //     if(res.success){
            //       that.$message.success(res.message);
            // that.$emit('ok');

            //     }else{
            //       that.$message.warning(res.message);
            //     }
            //   }).finally(() => {
            //     that.confirmLoading = false;
            //     that.close();
            //   })
            // }
            // })
        },
        handleCancel() {
            this.confirmLoading = false
            this.close()
        },
        onChange(e) {
            console.log('radio checked', e.target.value);
            let insdNum = e.target.value

            // this.setApplyArr[e.target.value]
            // console.log(this.model)
            this.codeQerForm.amount = this.model.bond//金额待定
            this.codeQerForm.appId = this.setArr[e.target.value].vuale
            this.codeQerForm.wayCode = this.setArr[e.target.value].applyId
            console.log(this.setArr, this.codeQerForm)
            //  下订单并返回支付二维码
            let ApplyQRCodeUrl = "/pay/pay/payOrders"
            this.QrListArr = []
            httpAction(ApplyQRCodeUrl, this.codeQerForm, 'post').then((res) => {
                let iseromasd = JSON.parse(res.result)
                this.orderParameter = iseromasd.data
                console.log("下订单并返回支付二维码", JSON.parse(res.result),)
                switch (this.codeQerForm.wayCode) {
                    case "ALI_PC":
                        console.log("支付宝PC网站")
                        const url = iseromasd.data.payData
                        window.open(url, '_blank');
                        break;

                    case "WX_NATIVE":
                        console.log("wx二维码")
                        this.QrListArr.push(iseromasd.data.payData)
                        break;
                    default:
                        break;
                }
                // 递归查询订单状态
                this.recursiveFunction()
            }).finally(() => {
            })
        },















        async recursiveFunction() {
            // 添加定时器延迟3秒
            await this.delay(3000);
            const response = await this.callBackendAPI();
            const responseData = JSON.parse(response.result); // 假设后端接口返回的数据在 response.data 中
            console.log("查询支付订单", responseData)
            if (this.recursionEndsClosed == true) {
                return
            }
            // 判断是否满足终止条件，例如 responseData.data.records[0].state 等于 2
            // responseData.data.records[0].state   0-订单生成, 1-支付中, 2-支付成功, 3-支付失败, 4-已撤销, 5-已退款, 6-订单关闭
            if (responseData.data.records[0].state === 2) {
                console.log("State is 2, stopping recursion.");
                const customHeaders = {
                    // 在这里设置请求头，例如Authorization等
                    Authorization: this.modelUserInfo.token,
                }
                // 查询该公告的投标人信息
                let bidderiInformation = {
                    tenderProjectId: this.model.tenderProjectId,
                    bidder: this.modelUserInfo.username,
                }
                let bidderiInformationUrl = "/project/bidProject/editStatus"
                postTeAction(bidderiInformationUrl, bidderiInformation, customHeaders).then((res) => {
                    console.log("修改投标人信息", res)
                    // //  修改状态
                    // let informationBidderAmend = {
                    //     id: res.result.records[0].id,
                    //     registrationStatus: "3",
                    // }
                    // let informationBidderAmendUrl = "/project/bidProject/edit"
                    // postTeAction(informationBidderAmendUrl, informationBidderAmend,customHeaders).then((resEdit) => {
                    //     console.log("修改投标人信息", resEdit)

                    // }).finally(() => {
                    // })
                }).finally(() => {
                })


                this.countDown()
                this.$emit('child-event');
                this.visible = false
                setTimeout(() => {
                    this.$refs.announceFileDownload.edit(this.model, this.modelUserInfo)
                }, 5 * 1000);
                return;
            }
            if (responseData.data.records[0].state === 3) {
                console.log("State is 3, stopping recursion.");
                return;
            }

            console.log("Calling API again...");
            await this.recursiveFunction();
        },
        async callBackendAPI() {
            // 调用后端接口，并返回 Promise 对象
            // 这里使用示例，你需要替换为实际的后端接口调用逻辑
            let trackOrderUrl = "/pay/pay/orderList?unionOrderId=" + this.orderParameter.payOrderId + "&wayCode=" + this.codeQerForm.wayCode;
            return await httpAction(trackOrderUrl, '', 'get');
        },
        delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        countDown() {
            this.$emit('child-event');
            let secondsToGo = 5;
            const modal = this.$success({
                title: '支付成功',
                content: `该弹窗将在 ${secondsToGo} 秒后关闭.`,
            });
            const interval = setInterval(() => {
                secondsToGo -= 1;
                modal.update({
                    content: `该弹窗将在 ${secondsToGo} 秒后关闭`,
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(interval);
                modal.destroy();
            }, secondsToGo * 1000);
        },
        countLoseDown() {
            let secondsToGo = 5;
            const modal = this.$error({
                title: '支付失败',
                content: `该弹窗将在 ${secondsToGo} 秒后关闭.`,
            });
            const interval = setInterval(() => {
                secondsToGo -= 1;
                modal.update({
                    content: `该弹窗将在 ${secondsToGo} 秒后关闭`,
                });
            }, 1000);
            setTimeout(() => {
                clearInterval(interval);
                modal.destroy();
            }, secondsToGo * 1000);
        },
    }
}
</script>
  
<style scoped>
.QR_codeImg {
    width: 100%;
    /* margin: 0 auto; */
    /* border: 1px solid #e0e0e0; */
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.QR_codeImg img {
    width: 200px;
    max-height: 200px;
    margin: 20px;
}

.QR_codeImg_btn {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.code_btn_need {
    min-width: 120px;

}

.code_btn_noneed {
    min-width: 120px;
    margin-top: 10px;
}
</style>
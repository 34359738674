var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"0 100px"}},[_c('div',[_c('a-button',{staticClass:"fhbtn",attrs:{"type":"danger","shape":"circle","size":"small"},on:{"click":function($event){return _vm.returnOperation()}}},[_c('a-icon',{attrs:{"type":"arrow-left"}})],1)],1),_c('div',{staticClass:"details_middle"},[_c('p',{staticStyle:{"text-align":"center","font-size":"28px","font-weight":"400","color":"#1f2f3d"}},[_vm._v(_vm._s(_vm.recorddetails.title))]),_c('p',{staticStyle:{"text-align":"center","font-size":"14px","color":"#5e6d82","line-height":"1.5em"}},[_c('span',[_vm._v("发布时间："+_vm._s(_vm.recorddetails.releaseTime))])]),_c('div',{staticStyle:{"padding":"0 15px"}},[_c('a-divider')],1),(_vm.txtlogin == '没登录信息' &&
      // recorddetails.projectStatus != 4 &&
      _vm.recorddetails.projectStatus != 5 &&
      _vm.recorddetails.projectStatus != 9 &&
      _vm.recorddetails.type == 1
      )?_c('div',{staticStyle:{"text-align":"right"}},[_c('a-button',{staticClass:"originalbtn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.notregistered()}}},[_c('span',[_vm._v("报名")])])],1):_vm._e(),(_vm.txtlogin == '有登录信息' &&
      _vm.recorddetails.projectStatus != 4 &&
      _vm.recorddetails.projectStatus != 5 &&
      _vm.recorddetails.projectStatus != 7 &&
      _vm.recorddetails.projectStatus != 9 &&
      _vm.recorddetails.type == 1
      )?_c('div',{staticStyle:{"text-align":"right"}},[(_vm.recorddetails.type == 1 &&
        (_vm.recorddetails.registrationStatus == null || _vm.recorddetails.registrationStatus == 1) &&
        _vm.recorddetails.projectStatus != 4 &&
        _vm.recorddetails.projectStatus != 5 &&
        _vm.recorddetails.projectStatus != 7 &&
        _vm.recorddetails.projectStatus != 9 &&
        (_vm.userInformation.roleType == 1 ||
          _vm.userInformation.roleType == null ||
          _vm.userInformation.roleType == undefined ||
          _vm.userInformation.roleType == '')
        )?_c('a-button',{staticClass:"originalbtn",attrs:{"type":"primary"},on:{"click":function($event){return _vm.registrationandpayment(_vm.recorddetails.registrationStatus, '调用报名')}}},[_c('span',[_vm._v("报名")])]):_vm._e(),(_vm.recorddetails.type == 1 &&
        _vm.recorddetails.registrationStatus == 2 &&
        _vm.recorddetails.projectStatus != 4 &&
        _vm.recorddetails.projectStatus != 5 &&
        _vm.recorddetails.projectStatus != 7 &&
        _vm.recorddetails.projectStatus != 9 &&
        (_vm.userInformation.roleType == 1 ||
          _vm.userInformation.roleType == null ||
          _vm.userInformation.roleType == undefined ||
          _vm.userInformation.roleType == '')
        )?_c('a-button',{staticClass:"xqbmbtn",staticStyle:{"padding":"0 15px","margin-bottom":"15px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.registrationandpayment(_vm.recorddetails.registrationStatus, '调用缴费')}}},[_c('span',[_vm._v("缴费")])]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"tstimg",staticStyle:{"max-width":"900px","margin":"0 auto","font-size":"14px","color":"#5e6d82","line-height":"1.5em"}},[_c('div',{staticClass:"tsbj",domProps:{"innerHTML":_vm._s(_vm.recorddetails.illustrate)}})])]),_c('div',{staticStyle:{"height":"100px","width":"100%"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }